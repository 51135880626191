import React from "react"
import { Button } from "components/Button"

const NavTabsRoutingBasedButtons = ({
  data = [],
  classes = "",
  isActive = undefined,
  size = "lg",
}) => {
  // console.log(``)
  //  ${props.isActive &&  props.isActive[el.identifier] === true ? 'gt-btn-grad-primary' : "gt-btn-secondary"}
  return (
    <>
      {data.length &&
        data.map((item, index) => {
          return (
            <div
              key={index}
              className={` mx-4 d-flex justify-content-center align-items-center ${
                isActive !== undefined &&
                (isActive[item.identifier] === true
                  ? "bg-navitems-primary"
                  : "bg-navitems-secondary")
              }`}
              onClick={evt => item.onClick(true)}
            >
              <img
                src={
                  isActive !== undefined &&
                  (isActive[item.identifier] === true
                    ? item.activeIcon
                    : item.icon)
                }
              />
              <a
                // style={{ height: "auto" }}
                size={size}
                // type="submit"
                className={`${classes} ${item.classesButton} `}
              >
                {item?.title}
              </a>
            </div>
          )
        })}
    </>
  )
}

export default NavTabsRoutingBasedButtons

// import React, { useState } from "react"
// import {
//   TabContent,
//   TabPane,
//   Nav,
//   NavItem,
//   NavLink,
//   Row,
//   Col,
// } from "reactstrap"
// import classnames from "classnames"

// const NavTabs = props => {
//   const {
//     navData,
//     defaultActiveTab,
//     navtabClasses = "",
//     setActiveTabValue = () => {},
//     route,
//   } = props
//   const [activeTabSecond, setActiveTabSecond] = useState(defaultActiveTab)

//   const toggle = tab => {
//     if (activeTabSecond !== tab) {
//       setActiveTabSecond(tab)
//       setActiveTabValue(tab)
//     }
//   }

//   return (
//     <>
//       <div className="fm-nav-tabs-border">
//         <Nav tabs className={`border-0 ${navtabClasses}`}>
//           {navData &&
//             navData.map(el => {
//               return (
//                 <NavItem key={el.identifier}>
//                   <NavLink
//                     className={classnames({
//                       active: activeTabSecond === el.identifier,
//                     })}
//                     onClick={() => {
//                       toggle(el.identifier)
//                     }}
//                   >
//                     {el.title}
//                   </NavLink>
//                 </NavItem>
//               )
//             })}
//         </Nav>
//         <TabContent activeTab={activeTabSecond}>
//           {navData &&
//             navData.map(el => {
//               return (
//                 <TabPane tabId={el.identifier} key={el.identifier}>
//                   <Row>
//                     <Col sm="12">
//                       {activeTabSecond == el.identifier && el.content}
//                     </Col>
//                   </Row>
//                 </TabPane>
//               )
//             })}
//         </TabContent>
//       </div>
//     </>
//   )
// }

// export default NavTabs
