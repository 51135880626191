import * as Yup from "yup"

export const initialValues = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  role: "",
}

export const validationSchema = Yup.object({
  firstName: Yup.string().required("Enter First Name"),
  lastName: Yup.string().required("Enter Last Name"),
  emailAddress: Yup.string().required("Enter Email"),
  role: Yup.string().required("Enter Role"),
})
