import React, { useState } from "react"
import { Col, Row } from "reactstrap"
import toast from "react-hot-toast"

// import financeDelIcon from "assets/images/icons/finance-vendor-mang-del-icon.svg"
import { DataTable } from "components/DataTable"
import financeEditIcon from "../../../../assets/images/icons/finance-vendor-mang-edit-icon.svg"
import { Modal } from "components/Modal"
import { Pagination } from "components/Pagination"
import AddEditModal from "../modals/AddEditModal"
import DeleteModal from "../modals/DeleteModal"
import { Header } from "../../Common/Header"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import {
  DepartmentListing,
  AddDepartment,
  UpdateDepartment,
  DeleteDepartment,
} from "api/api.service"
import { truncateString } from "utils/commonUtils"

const COLUMNS = {
  S_NO: "S.No",
  DEPARMENT_NAME: "Name of the Department",
  ADDRESS: "Address",
  LOCATION: "Location",
  ACTIONS: "Action",
}

const MODAL_ACTIONS = {
  ADD: "1",
  EDIT: "2",
  DELETE: "3",
}

const dataKey = "data"
const totalKey = "total"
const CHARACTER_LIMIT = 20

const DepartmentLocationListing = ({}) => {
  const [actionStates, setActionStates] = useState({
    isAddDepartment: null,
    isEditDepartment: null,
    isDeleteModal: null,
  })
  const [currentActionKey, setCurrentActionKey] = useState(null)
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isDataUpdated, setIsDataUpdated] = useState(false)
  const [recordDataID, setRecordDataID] = useState(undefined)
  // const [departmentData, setDepartmentData] = useState(null);
  const [filters, setFilters] = useState(null)
  const {
    data,
    isFetching,
    page,
    searchText,
    total,
    pageSize,
    request,
    onChangePageSize,
    handlePageClick,
    handleSearchChange,
  } = useLocalPaginatedRequest({
    requestFn: DepartmentListing,
    params: {
      ...(filters?.Status && { Status: filters?.Status }),
    },
    deps: [filters, isDataUpdated],
    dataKey: dataKey,
    totalKey: totalKey,
  })

  const handleEditDepartment = data => {
    setSelectedRowData(data)
    setCurrentActionKey(MODAL_ACTIONS.EDIT)
    setActionStates(prev => ({ ...prev, isEditDepartment: true }))
  }

  const handleDeleteModal = data => {
    setRecordDataID(data.id)
    setCurrentActionKey(MODAL_ACTIONS.DELETE)
    setActionStates(prev => ({ ...prev, isDeleteModal: true }))
  }

  let currentAction = {
    [MODAL_ACTIONS.ADD]: {
      title: "Add New Department",
      btnText: "Add Department",
    },
    [MODAL_ACTIONS.EDIT]: {
      title: "Edit Department",
      btnText: "Edit Department",
    },
    [MODAL_ACTIONS.DELETE]: {
      title: "Delete Department",
      btnText: "Delete Department",
      value: "Department",
    },
  }

  const handleCloseModal = () => {
    setActionStates({
      isAddDepartment: null,
      isEditDepartment: null,
      isDeleteModal: null,
    })
    setCurrentActionKey(null)
  }

  const handleAddDepartment = () => {
    setSelectedRowData(null)
    setCurrentActionKey(MODAL_ACTIONS.ADD)
    setActionStates(prev => ({ ...prev, isAddDepartment: true }))
  }

  const handleWarehouseLocationSubmit = values => {
    let { name, address, location, userId, warehouseId } = values
    let payload = {}

    if (actionStates.isEditDepartment) {
      payload = {
        departmentName: name,
        address,
        location,
        status: "ACTIVE",
      }
    } else if (actionStates.isAddDepartment) {
      payload = {
        warehouseId: warehouseId,
        departmentName: name,
        address,
        location,
        userId: currentActionKey == "2" ? selectedRowData.userId : userId,
        status: "ACTIVE",
      }
    }

    handleSumbitForm(payload)
  }

  const handleSumbitForm = async formBody => {
    try {
      setIsLoading(true)
      let response

      if (currentActionKey == "2") {
        response = await UpdateDepartment(selectedRowData.id, formBody, false)
      } else {
        response = await AddDepartment(formBody, false)
      }
      updateAPISuccess()
      toast.success("Success !")
      request()
      handleCloseModal()
    } catch (err) {
      toast.error(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteRecordHandler = async () => {
    try {
      setIsLoading(true)
      let response = await DeleteDepartment(recordDataID)
      updateAPISuccess()
      toast.success("Department has been deleted successfully!")
      request()
      handleCloseModal()
    } catch (err) {
      toast.error(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const updateAPISuccess = () => setIsDataUpdated(!isDataUpdated)

  return (
    <>
      <Row>
        <Col>
          <Header
            btnTitle="Add New Department"
            onClick={handleAddDepartment}
            searchText={searchText}
            onChangeHandler={handleSearchChange}
            flexResponsive="location-flex-responsiveness"
            searchResponsiveness="location-searchResponsiveness"
            flexResponsiveInner="location-flexResponsiveInner"
            headerBtnResponsive="ad-location-header-btn-responsive"
            SearchBoxContainer="admin-location-search-box-container"
          />
        </Col>
        <Col md={12}>
          <DataTable
            data={data}
            loading={isFetching}
            className="location-data-container mt-3"
            tableClasses="ad-location-table"
            config={[
              {
                title: COLUMNS.S_NO,
                className: "black-color fm-ftwg-600  ",
                render: data => data?.id || "-",
              },
              {
                title: COLUMNS.DEPARMENT_NAME,
                className: "",
                render: data => data?.departmentName || "-",
              },
              {
                title: COLUMNS.ADDRESS,
                className: "",
                render: data => {
                  if (data?.address?.length > CHARACTER_LIMIT) {
                    return (
                      <div data-container={data?.address}>
                        {truncateString(data?.address, CHARACTER_LIMIT)}
                      </div>
                    )
                  } else {
                    return data?.address || "-"
                  }
                },
              },
              {
                title: COLUMNS.LOCATION,
                className: "",
                render: data => data?.location || "-",
              },
              {
                title: COLUMNS.ACTIONS,
                className: "",
                render: data => (
                  <div className="d-flex justify-content-between location-icon-container">
                    <span
                      className=" pointer "
                      onClick={e => handleEditDepartment(data)}
                    >
                      <img src={financeEditIcon} />
                    </span>
                    {/* <span
                      className=" pointer "
                      onClick={() => handleDeleteModal(data)}
                    >
                      <img src={financeDelIcon} />
                    </span> */}
                  </div>
                ),
              },
            ]}
            // loading={false}
          />
        </Col>
      </Row>

      <Pagination
        currentPage={page}
        totalData={total}
        onPageClick={handlePageClick}
      />

      <Modal
        isOpen={!!currentAction[currentActionKey]}
        handleModalToggling={handleCloseModal}
        bodyClassName="p-0"
        customButton={true}
        hideModalHeaderSeparator={true}
        headerClasses="modal-header-container"
        size="md"
        modalTitle={currentAction[currentActionKey]?.title}
      >
        {(actionStates.isAddDepartment || actionStates.isEditDepartment) && (
          <AddEditModal
            btnText={currentAction[currentActionKey].btnText}
            activeModalAction={currentActionKey}
            data={selectedRowData}
            onSubmitForm={handleWarehouseLocationSubmit}
            isLoading={isLoading}
            type="DEPARTMENT"
          />
        )}
        {actionStates.isDeleteModal && (
          <DeleteModal
            value={currentAction[currentActionKey]?.value}
            handleModalClose={handleCloseModal}
            handleDeleteRecord={deleteRecordHandler}
          />
        )}
      </Modal>
    </>
  )
}

export default DepartmentLocationListing
