import React, { useState } from "react"
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
} from "reactstrap"

import WarehouseIconActive from "../../../assets/images/icons/warehouse.png"
import WarehouseIcon from "../../../assets/images/icons/warehouse-deactive.png"
import DepartmentIconActive from "../../../assets/images/icons/storeIcon.png"
import DepartmentIcon from "../../../assets/images/icons/storeIcon-deactive.png"
import globalinventory from "../../../assets/images/icons/admin-finance-global-inventory.png"
import ItemCategoryNavButtons from "./InnerNavButtons"
import ItemsIconActive from "../../../assets/images/icons/items-icon-4.png"
import ItemsIcon from "../../../assets/images/icons/items-icon-disabled-4.png"
 
import Listing from "./WarehouseGeneralInvListing"
import WHCHListing from "./WarehouseChemicalListing"
import WHTLListing from "./WarehouseToolsListing"
import DPGIListing from "./DepartmentGenaralInvListing"
import DPTLListing from "./DepartmentToolsListing"
import ItemCategoryListing from "../../Common/InvManagementItemsTab/ItemCategoryListing"
import ItemsListing from "../../Common/InvManagementItemsTab/ItemsListing"


const TopNavButtons = () => {
  const [activeTab, setActiveTab] = useState("1")

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const WarehouseNavItems = [
    {
      title: "General Inventory",
      identifier: "ic1",
      content: <Listing />,
    },
    {
      title: "Chemicals",
      identifier: "ic2",
      content: <WHCHListing />,
    },
    {
      title: "Tools",
      identifier: "ic3",
      content: <WHTLListing />,
    },
  ]
  const DepartmentNavItems = [
    {
      title: "General Inventory",
      identifier: "ic1",
      content: <DPGIListing />,
    },
    {
      title: "Tools",
      identifier: "ic3",
      content: <DPTLListing />,
    },
  ] 
  const ItemNavItems = [
    {
      title: "Items",
      identifier: "ic1",
      content: <ItemsListing />,
    },
    {
      title: "Category",
      identifier: "ic2",
      content: <ItemCategoryListing />,
    },
  ]
  const TopHeaderNavItems = [
    {
      title: "Warehouse",
      identifier: "1",
      className: "mx-2",
      activeIcon: WarehouseIconActive,
      icon: WarehouseIcon,
      content: (
        <ItemCategoryNavButtons
          heading="Inventory"
          iconClass="inventory-req-icon"
          navItems={WarehouseNavItems}
          containerClassName="warehouse-nav-tabs"
        />
      ),
    },
    {
      title: "Department",
      identifier: "2",
      className: "mx-2",
      activeIcon: DepartmentIconActive,
      icon: DepartmentIcon,
      content: (
        <ItemCategoryNavButtons
          heading="Inventory"
          iconClass="inventory-req-icon"
          navItems={DepartmentNavItems}
          containerClassName="warehouse-nav-tabs"
        />
      ),
    },
    {
      title: "Item",
      identifier: "3",
      className: "mx-2",
      activeIcon: ItemsIconActive,
      icon: ItemsIcon,
      content: (
        <ItemCategoryNavButtons
          heading="Inventory"
          iconClass="inventory-req-icon"
          navItems={ItemNavItems}
          containerClassName="warehouse-nav-tabs"
        />
      ),
    },
  ]
  return (
    <>
      <div className="casita-container-wrapper">
        <Container fluid>
          <Row>
            <Col className="col-12 ">
              <div className="mb-3">
                <h1 className="heading-color gi-top-heading">Inventory Management</h1>
              </div>
              <div className="admin-location mb-4">
                <div className="al-navtabs-main">
                  <Nav tabs className="al-nav-tabs">
                    {TopHeaderNavItems &&
                      TopHeaderNavItems.map(el => {
                        return (
                          <NavItem
                            key={el.identifier}
                            className={`${el.className} al-nav-item d-flex`}
                          >
                            <div
                              className={`mx-4 d-flex justify-content-center align-items-center ${
                                activeTab === el.identifier
                                  ? "bg-navitems-primary"
                                  : "bg-navitems-secondary"
                              }`}
                            >
                              <img
                                className="item-head-img"
                                src={
                                  activeTab === el.identifier
                                    ? el.activeIcon
                                    : el.icon
                                }
                              />
                              <a
                                className="ps-1 d-inline-block al-nav-link "
                                onClick={() => {
                                  toggle(el.identifier)
                                }}
                              >
                                {el.title}
                              </a>
                            </div>
                          </NavItem>
                        )
                      })}
                  </Nav>
                </div>
              </div>
              <div>
                <TabContent activeTab={activeTab}>
                  {TopHeaderNavItems &&
                    TopHeaderNavItems.map(el => {
                      return (
                        <TabPane tabId={el.identifier} key={el.identifier}>
                          <Row>
                            <Col sm="12">
                              {el.identifier == activeTab && el.content}
                            </Col>
                          </Row>
                        </TabPane>
                      )
                    })}
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default TopNavButtons
