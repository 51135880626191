import React, { useState } from "react"
import { Col, Row } from "reactstrap"
import { Switch, Route, useHistory, Redirect } from "react-router-dom"

import { APP_ROUTES } from "helpers/routeHelpers"

import { NavTabsRoutingBasedButtons as NavButtons } from "../../AdminApp/Common/NavTabsRoutingBased"
import WarehouseIconActive from "../../../assets/images/icons/warehouse.png"
import WarehouseIcon from "../../../assets/images/icons/warehouse-deactive.png"
import DepartmentIconActive from "../../../assets/images/icons/storeIcon.png"
import DepartmentIcon from "../../../assets/images/icons/storeIcon-deactive.png"

import WarehouseListing from "./warehouse/WarehouseListing"
import DepartmentListing from "./department/DepartmentListing"

const LocationsListing = () => {
  const pathURL = window.location.pathname
  const isDeprtment =
    pathURL === "/locations"
      ? false
      : pathURL === "/locations/department"
      ? true
      : false
  const isWarehouse =
    pathURL === "/locations"
      ? true
      : pathURL === "/locations/warehouse"
      ? true
      : false

  let history = useHistory()
  const [isActive, setIsActive] = useState({
    departmentLocation: isDeprtment,
    warehouseLocation: isWarehouse,
  })

  const handleDepartmentLocationClick = () => {
    setIsActive({
      ...isActive,
      departmentLocation: true,
      warehouseLocation: false,
    })
    history.push(APP_ROUTES.ADMIN_LOCATION_DEPARTMENT)
  }

  const handleWarehouseLocationClick = () => {
    setIsActive({
      ...isActive,
      departmentLocation: false,
      warehouseLocation: true,
    })
    history.push(APP_ROUTES.ADMIN_LOCATION_WAREHOUSE)
  }
  const mainBtnsConfig = [
    {
      title: "Warehouse",
      onClick: handleWarehouseLocationClick,
      classesButton: "al-nav-link",
      identifier: "warehouseLocation",
      icon: WarehouseIcon,
      activeIcon: WarehouseIconActive,
    },
    {
      title: "Department",
      onClick: handleDepartmentLocationClick,
      classesButton: "al-nav-link",
      identifier: "departmentLocation",
      icon: DepartmentIcon,
      activeIcon: DepartmentIconActive,
    },
  ]

  const routesConfig = [
    {
      name: "Department Location",
      isExact: false,
      component: DepartmentListing,
      path: APP_ROUTES.ADMIN_LOCATION_DEPARTMENT,
    },
    {
      name: "Warehouse Location",
      isExact: false,
      component: WarehouseListing,
      path: APP_ROUTES.ADMIN_LOCATION_WAREHOUSE,
    },
  ]

  return (
    <>
      <Row>
        <Col className="col-12">
          <h1>Locations</h1>
          <div className="admin-location mb-3">
            <div className="al-navtabs-main">
              <div className="al-nav-tabs">
                <div className="al-nav-item d-flex">
                  <NavButtons
                    data={mainBtnsConfig}
                    classes={"ps-1 d-inline-block"}
                    isActive={isActive}
                  />
                </div>
              </div>
            </div>
          </div>
          <Switch>
            {routesConfig.map(({ name, isExact, path, component }, idx) => (
              <Route
                key={idx}
                name={name}
                exact={isExact}
                path={path}
                component={component}
              />
            ))}
            <Redirect to={APP_ROUTES.ADMIN_LOCATION_WAREHOUSE} />
          </Switch>
        </Col>
      </Row>
    </>
  )
}

export default LocationsListing
