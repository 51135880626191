import React, { useState } from "react"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../../assets/images/users/avatar-4.jpg"
import boxIcon from "../../../assets/images/box-icon.svg"
import briefCaseIcon from "../../../assets/images/briefcase-icon.svg"
import giftIcon from "../../../assets/images/gift-icon.svg"
import { useSelector } from "react-redux"
import NotiticationsList from "./NotiticationsList"

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const unreadNotiticationCount = useSelector(
    state => state.notifications?.unreadNotificationCount
  )

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block gt-menu-notifcation-dropdown"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          {!!unreadNotiticationCount && (
            <span className="badge bg-danger rounded-pill">
              {unreadNotiticationCount}
            </span>
          )}
        </DropdownToggle>

        {menu && <NotiticationsList closeMenu={() => setMenu(false)} />}
      </Dropdown>
    </React.Fragment>
  )
}

export default NotificationDropdown
