import { Get, Put, Post, Delete } from "./api.helper"
import { store } from "store"
import Config from "Config"
import { appendQueryParams } from "utils/urlUtils"
// **** AUTH APIS
export const Login = (bodyData = {}, showToaster = true) => {
  return Post({
    url: "/auth/login",
    body: bodyData,
    isAuthorized: false,
    showToaster,
  })
}

export const GetMe = () => {
  return Get({ url: "/user" })
}

export const ForgotPassword = (bodyData = {}, showToaster = true) => {
  return Post({
    url: "/auth/forget-password",
    body: bodyData,
    showToaster,
  })
}
export const ChangePassword = (bodyData = {}, showToaster = true) => {
  return Post({
    url: "/user/change-password",
    body: bodyData,
    showToaster,
  })
}

export const ForgotPasswordVerification = (
  bodyData = {},
  showToaster = true
) => {
  return Post({
    url: "/auth/forget-password/verification",
    body: bodyData,
    showToaster,
  })
}

export const ResetPassword = (bodyData = {}, showToaster = true) => {
  return Post({
    url: "/auth/reset-password",
    body: bodyData,
    showToaster,
  })
}

export const CSVFileUpload = (bodyData = {}, showToaster = true) => {
  console.log('body', bodyData)
  const token = store.getState().userAuth.user.Token
  // const url = appendQueryParams()
  return (
    fetch(`${Config.env().API_URL}/item/bulk-items`, {
      headers: {
        Authorization: token,
        // 'Content-Type': 'multipart/form-data'
        // "Content-Type": 'text/csv;charset=UTF-8',
      },
      body: bodyData,
      // responseType: "blob",
      method: "POST",
    })
      // .then(response => response.blob())
      .then(res => Promise.resolve(res))
      .catch(err => {})
  )
}

export const ResendOtpCode = (bodyData = {}, showToaster = true) => {
  return Post({
    url: "/auth/resend-otp",
    body: bodyData,
    showToaster,
  })
}

export const Logout = () => {
  return Post({
    url: "/user/logout",
  })
}

export const WarehouseInventoryListing = (params = {}, abortSignal = null) => {
  return Get({
    url: "/inventory/warehouse-inventory",
    params,
    abortSignal,
  })
}

// setting apis

export const notificationSetting = (bodyData = {}, showToaster = true) => {
  return Post({
    url: "/user/notification_setting",
    body: bodyData,
    showToaster,
  })
}

export const passwordSetting = (bodyData = {}, showToaster = true) => {
  return Post({
    url: "/user/change-password",
    body: bodyData,
    showToaster,
  })
}

//Finance Dashboard

export const GetStockListing = (params = {}) => {
  return Get({
    url: "/inventory/dashboard-shortage",
    params,
  })
}

export const GetAdminFinanceScrapReturnListing = (
  params = {},
  abortSignal = null
) => {
  return Get({ url: "/requests/warehouse-scrap-return", params, abortSignal })
}
export const AdminFinanceDashboardAnalytics = () => {
  return Get({
    url: "/inventory/dashboard",
  })
}

// export const DepartmentInventoryListing = ( params = {}, abortSignal = null ) => {
//   return Get({
//     url: "/inventory/department-inventory",
//     params,
//     abortSignal,
//   })
// }

export const ItemCategoryCategoriesListing = (
  params = {},
  abortSignal = null
) => {
  return Get({
    url: "/items-category/categories",
    params,
    abortSignal,
  })
}

export const GetDepartmentInventoryListing = (params = {}) => {
  return Get({ url: "/inventory/department-inventory", params })
}

export const GetDepartmentInventoryRequestListing = (
  params = {},
  abortSignal = null
) => {
  return Get({ url: "/inventory/department-requests", params, abortSignal })
}

export const AddDeparmentInventoryRequest = (
  bodyData = {},
  showToaster = true
) => {
  return Post({
    url: "/inventory/department-requests",
    body: bodyData,
    showToaster,
  })
}

export const AddWarehouseInventoryRequest = (
  bodyData = {},
  showToaster = true
) => {
  return Post({
    url: "/inventory/warehouse-requests",
    body: bodyData,
    showToaster,
  })
}

export const GetDepartmentsListing = (params = {}, abortSignal = null) => {
  return Get({ url: "/department/departments", params, abortSignal })
}

export const GetDepartmentById = (id = "") => {
  return Get({ url: `/department/${id}` })
}

export const GetItemsListing = (params = {}, abortSignal = null) => {
  return Get({ url: "/item/items", params, abortSignal })
}

export const GetItemById = (id = "") => {
  return Get({ url: `/item/${id}` })
}

export const GetWarehouseInventoryPODetails = (id = "") => {
  return Get({ url: `/inventory/items-purchase-orders/${id}` })
}

export const GetInventoryItemById = (id = "") => {
  return Get({ url: `/inventory/items-by-id/${id}` })
}

// **** FINANCE ORDER MANAGEMENT APIS
export const OrderRequestByItemsListing = (params = {}, abortSignal = null) => {
  return Get({
    url: "/inventory/finance-requests-by-items",
    params,
    abortSignal,
  })
}

export const OrderRequestByOrderListing = (params = {}, abortSignal = null) => {
  return Get({
    url: "/inventory/warehouse-requests",
    params,
    abortSignal,
  })
}

export const PlacedOrdersOMListing = (params = {}, abortSignal = null) => {
  return Get({
    url: "/inventory/purchase-orders",
    params,
    abortSignal,
  })
}

export const WarehouseRequestDetialViewById = id => {
  return Get({
    url: `/inventory/finance-requests-by-id/${id}`,
  })
}

export const InventoryItemsById = (id, params = {}, abortSignal = null) => {
  return Get({
    url: `/inventory/items-by-id/${id}`,
    params,
    abortSignal,
  })
}

export const VendorListing = (params = {}, abortSignal = null) => {
  return Get({
    url: "/vendor/vendors",
    params,
    abortSignal,
  })
}

export const CreateOrderFinance = (bodyData = {}, showToaster = true) => {
  return Post({
    url: "/inventory/finance-order",
    body: bodyData,
    showToaster,
  })
}

export const GetVendorById = (id = "") => {
  return Get({
    url: `/vendor/${id}`,
  })
}

export const CreateVendor = (bodyData = {}, showToaster = true) => {
  return Post({
    url: "/vendor",
    body: bodyData,
    showToaster,
  })
}

export const EditVendor = (bodyData, id, abortSignal = null) => {
  return Put({
    url: `/vendor/${id}`,
    body: bodyData,
    abortSignal,
  })
}

export const UserListing = (params = {}, abortSignal = null) => {
  return Get({
    url: "/user/listing",
    params,
    abortSignal,
  })
}

export const WarehouseListing = (params = {}, abortSignal = null) => {
  return Get({
    url: "/warehouse/listing",
    params,
    abortSignal,
  })
}

export const CreateUser = (bodyData = {}) => {
  return Post({
    url: "/user/user",
    body: bodyData,
  })
}

export const DepartmentListing = (params = {}, abortSignal = null) => {
  return Get({
    url: "/department/departments",
    params,
    abortSignal,
  })
}

export const GetUserListing = (params = {}, abortSignal = null) => {
  return Get({
    url: "/user/listing",
    params,
    abortSignal,
  })
}

export const AddWarehouse = (bodyData = {}, showToaster = true) => {
  return Post({
    url: "/warehouse/warehouse",
    body: bodyData,
    showToaster,
  })
}

export const EditUser = (bodyData = {}, id = "", abortSignal = null) => {
  return Put({
    url: `/user/${id}`,
    body: bodyData,
    abortSignal,
  })
}

export const UpdateWarehouse = (id, bodyData = {}, showToaster = true) => {
  return Put({
    url: `/warehouse/${id}`,
    body: bodyData,
    showToaster,
  })
}

export const AddDepartment = (bodyData = {}, showToaster = true) => {
  return Post({
    url: "/department",
    body: bodyData,
    showToaster,
  })
}

export const UpdateDepartment = (id, bodyData = {}, showToaster = true) => {
  return Put({
    url: `/department/${id}`,
    body: bodyData,
    showToaster,
  })
}

export const DeleteDepartment = (id, abortSignal = null) => {
  return Delete({
    url: `/department/${id}`,
    abortSignal,
  })
}

export const DeleteWarehouse = (id, abortSignal = null) => {
  return Delete({
    url: `/warehouse/${id}`,
    abortSignal,
  })
}

//  Media
export const uploadMediaInit = bodyData => {
  return Post({
    url: "/media/upload/init",
    body: bodyData,
  })
}

export const GetScrapReturnListing = (params = {}, abortSignal = null) => {
  return Get({ url: "/requests/scrap-return", params, abortSignal })
}

export const CreateScrapReturnRequest = (bodyData = {}) => {
  return Post({
    url: "/requests/scrap-return",
    body: bodyData,
  })
}

export const uploadMediaFinalize = bodyData => {
  return Post({
    url: "/media/upload/finalize",
    body: bodyData,
  })
}

export const uploadMedia = (url, blob) => {
  return fetch(url, {
    body: blob,
    method: "PUT",
  })
    .then(res => {
      return Promise.resolve(res)
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const GetScrapReturnRequestById = (id = "", params = {}) => {
  return Get({ url: `/requests/scrap-return/${id}`, params })
}

export const GetPullbackRequestListing = (params = {}, abortSignal = null) => {
  return Get({ url: "/requests/pullback-listing", params, abortSignal })
}

export const DepartmentFulfillmentByWarehouse = (
  bodyData = {},
  showToaster = true
) => {
  return Post({
    url: "/inventory/department-request",
    body: bodyData,
    showToaster,
  })
}

export const GetPdfReport = (params = {}, abortSignal = null) => {
  const token = store.getState().userAuth.user.Token
  const url = appendQueryParams(`${Config.env().API_URL}/pdf/pdf`, params)

  return (
    fetch(url, {
      headers: {
        Authorization: token,
        "Content-Type": " application/pdf",
      },
      // body: JSON.stringify(params),
      responseType: "blob",
      method: "GET",
    })
      // .then(response => response.blob())
      .then(res => Promise.resolve(res))
      .catch(err => {})
  )
}

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Below apis are of Gyft, we will remove it gradually, cox they are used in store.for now use them as an example
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// **** CATEGORY APIS
export const GetCategories = (params = {}) => {
  return Get({ url: "/category", params })
}

export const GetCategoryById = id => {
  return Get({ url: `/category/${id}` })
}

export const ItemCategoriesListing = (params = {}, abortSignal = null) => {
  return Get({
    url: "/items-category/categories",
    params,
    abortSignal,
  })
}

export const UpdateItemCategory = (id = "", bodyData = {}) => {
  return Put({
    url: `/items-category/${id}`,
    body: bodyData,
  })
}

export const AddItemCategory = (bodyData = {}) => {
  return Post({
    url: "/items-category",
    body: bodyData,
  })
}

export const AddNewItem = (bodyData = {}, showToaster = true) => {
  return Post({
    url: "/item",
    body: bodyData,
    showToaster,
  })
}

export const UpdateItem = (id = "", bodyData = {}, showToaster = true) => {
  return Put({
    url: `/item/${id}`,
    body: bodyData,
    showToaster,
  })
}

// **** PRODUCT APIS
export const GetProducts = (params = {}) => {
  return Get({ url: "/product", params })
}

// **** SETTINGS APIS
export const GetProductSizes = (params = {}) => {
  return Get({ url: "/settings/sizes", params })
}

export const GetSettings = (params = {}) => {
  return Get({ url: "/settings", params })
}

export const UpdateSettings = (bodyData = {}, showToaster = true) => {
  return Put({ url: "/settings", body: bodyData, showToaster })
}

// **** ORDER APIS
export const GetOrders = (params = {}) => {
  return Get({ url: "/order", params })
}

export const GetOrderById = id => {
  return Get({ url: `/order/${id}` })
}

// **** BOX APIS
export const BoxListing = (params = {}, abortSignal = null) => {
  return Get({
    url: "/box",
    params,
    abortSignal,
  })
}

export const GetBoxById = id => {
  return Get({ url: `/box/${id}` })
}

export const DeleteBox = (id, abortSignal = null) => {
  return Delete({
    url: `/box/${id}`,
    abortSignal,
  })
}

export const CreateNewBox = (bodyData = {}, showToaster = true) => {
  return Post({
    url: "/box",
    body: bodyData,
    showToaster,
  })
}

// **** BUSINESS APIS
export const BusinessAccountListing = (params = {}, abortSignal = null) => {
  return Get({
    url: "/business_account",
    params,
    abortSignal,
  })
}

export const GetBusinessAccountById = id => {
  return Get({ url: `/business_account/${id}` })
}

export const AddBusinessAccount = (bodyData, abortSignal = null) => {
  return Post({
    url: "/business_account",
    body: bodyData,
    abortSignal,
  })
}

export const EditBusinessAccount = (bodyData, id, abortSignal = null) => {
  return Put({
    url: `/business_account/${id}`,
    body: bodyData,
    abortSignal,
  })
}

export const ApproveBusinessAccount = (id, abortSignal = null) => {
  return Post({
    url: `/business_account/${id}/approve`,
    abortSignal,
  })
}

export const RejectBusinessAccount = (id, abortSignal = null) => {
  return Post({
    url: `/business_account/${id}/reject`,
    abortSignal,
  })
}

export const SuspendBusinessAccount = (id, abortSignal = null) => {
  return Post({
    url: `/business_account/${id}/suspend`,
    abortSignal,
  })
}

// **** GYFT APIS
export const GetGyfts = (params = {}, abortSignal = null) => {
  return Get({
    url: "/gyfts",
    params,
    abortSignal,
  })
}

export const GetGyftsCount = (params = {}) => {
  return Get({ url: "/gyfts/count", params })
}

// **** TEAMMATE APIS
export const GetTeammates = (params = {}) => {
  return Get({ url: "/user/teammates", params })
}

export const ActivateTeammate = (id, abortSignal = null) => {
  return Post({
    url: `/user/teammate/${id}/activate`,
    abortSignal,
  })
}

export const SuspendTeammate = (id, abortSignal = null) => {
  return Post({
    url: `/user/teammate/${id}/suspend`,
    abortSignal,
  })
}

// **** REPORTS APIS
export const GetReports = (params = {}) => {
  return Get({ url: "/report", params })
}

// **** DASHBOARD APIS
export const GetDashboardCardsSummary = (params = {}, abortSignal = null) => {
  return Get({
    url: "/analytics/summary",
    params,
    abortSignal,
  })
}

export const GetTopBusinesses = (params = {}, abortSignal = null) => {
  return Get({
    url: "/analytics/businesses/top",
    params,
    abortSignal,
  })
}

export const GetMonthlyOrderSummary = (params = {}, abortSignal = null) => {
  return Get({
    url: "/analytics/order/summary",
    params,
    abortSignal,
  })
}

export const GetTotalEarnings = (params = {}, abortSignal = null) => {
  return Get({
    url: "/analytics/earnings",
    params,
    abortSignal,
  })
}

export const GetTotalGyftsSent = (params = {}, abortSignal = null) => {
  return Get({
    url: "/analytics/gyfts/count",
    params,
    abortSignal,
  })
}

// **** NOTIFICATION APIS
export const GetNotifications = (params = {}, abortSignal = null) => {
  return Get({
    url: "/notification",
    params,
    abortSignal,
  })
}

export const MarkNotificationsAsRead = (bodyData = {}, abortSignal = null) => {
  return Post({
    url: `/notification/read-notifications`,
    body: bodyData,
    abortSignal,
  })
}

export const GetGlobalQuantityById = (id = "", params = {}) => {
  return Get({ url: `/inventory/items-global-quantities/${id}`, params })
}
