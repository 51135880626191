import Config from "Config"
import { useState, useEffect, useRef } from "react"
import { checkIfScrolledToBottom } from "utils/domUtils"
import direction from "../constants/SortOrder"

export const useInfiniteScrollRequest = ({
  requestFn,
  params = {},
  deps = [],
  searchKey = "Q",
  dataKey = "Data",
  totalKey = "TotalCount",
  dataId = null,
  successCallback = () => {},
}) => {
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")
  const [data, setData] = useState([])
  const [total, setTotal] = useState("")
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    request()
  }, [page, searchText, ...deps])

  const request = () => {
    setIsFetching(true)
    requestFn(
      {
        Limit: Config.LIMIT,
        Page: page,
        Direction: direction.DESC,
        Column: "CreatedAt",
        ...(searchText && { [searchKey]: searchText }),
        ...(Object.keys(params).length && params),
      },
      null,
      dataId
    )
      .then(res => {
        setData(prevData => [...prevData, ...res[dataKey]])
        setTotal(res[totalKey])
        successCallback(data)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  const handleSearchChange = e => {
    let searchText = e.target.value
    setSearchText(searchText)
    setPage(1)
  }

  const handlePageClick = pageNo => {
    setPage(pageNo)
  }

  const handleScroll = e => {
    if (e.target && data && data.length) {
      const dataAvailable = total - data.length
      if (checkIfScrolledToBottom(e.target) && dataAvailable && !isFetching) {
        handlePageClick(page + 1)
      }
    }
  }

  return {
    page,
    searchText,
    handleSearchChange,
    handlePageClick,
    handleScroll,
    data,
    total,
    request,
    isFetching,
  }
}
