import React from "react"

import { OrderRequestByItem, OrderRequestByOrder } from "./OrderRequests"

const OrderRequestsListing = ({ sortBy, handleTogglerChange }) => {
  return (
    <>
      <div
        className={`${
          sortBy ? "byorder-toggle" : ""
        } fm-itemsorders-toggle-con my-auto`}
      >
        <span className={`fm-toggle-typo ${!sortBy ? "active" : undefined}`}>
          By Items
        </span>
        <label className="switch mx-2 my-0">
          <input
            type="checkbox"
            defaultChecked={sortBy}
            onChange={e => handleTogglerChange(!sortBy)}
          />
          <span className="slider round"></span>
        </label>
        <span className={`fm-toggle-typo ${sortBy ? "active" : undefined}`}>
          By Orders
        </span>
      </div>
      {sortBy ? (
        <OrderRequestByOrder sortBy={sortBy} />
      ) : (
        <OrderRequestByItem />
      )}
    </>
  )
}

export default OrderRequestsListing
