export const scrollToBottom = scrollContainer => {
  if (scrollContainer) {
    scrollContainer.scrollTop =
      scrollContainer.scrollHeight - scrollContainer.clientHeight
  }
}

export const checkIfScrolledToBottom = container => {
  let subtractingResult =
    Math.round(container?.scrollHeight) - Math.round(container?.scrollTop)
  subtractingResult = Math.round(subtractingResult)
  if (
    subtractingResult - Math.round(container?.clientHeight) == 0 ||
    (subtractingResult - Math.round(container?.clientHeight) <= 1 &&
      subtractingResult - Math.round(container?.clientHeight) >= -1)
  ) {
    return true
  }
  return false
}
