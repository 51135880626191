import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"

import { AnalyticsBox } from "components/AnalyticsBox"
import globalinventory from "../../assets/images/icons/admin-finance-global-inventory.png"
import warehouseinventory from "../../assets/images/icons/admin-finance-warehouse-inventory.png"
import departmentInventory from "../../assets/images/icons/admin-finance-department-inventory.png"
import { DataTable } from "components/DataTable"
import {
  GetStockListing,
  GetScrapReturnListing,
  AdminFinanceDashboardAnalytics,
} from "api/api.service"
import { store } from "store"
import { Modal } from "components/Modal"
import ItemListing from "./modal/ItemListing"
import { toast } from "react-hot-toast"
import { Button } from "components/Button"
import { useHistory } from "react-router-dom"
import { APP_ROUTES } from "helpers/routeHelpers"
import { getLimitedItems } from "utils/commonUtils"
import { convertDateTime, dateTimeFormat } from "utils/dateFormatUtils"

const COLUMNS = {
  SKU_NO: "SKU.No",
  ITEM_NAME: "Item Name",
  TYPE: "Type",
  QUANTITY: "Quantity",
  DEPARTMENT: "Department",
  DATE_OF_REQUEST: "Requested Date",
  ISSUES: "Issue",
}

const PRODUCT_STOCK_CONST = {
  MINOR_SHORTAGE: 1,
  MAJOR_SHORTAGE: 2,
  FULLSTOCK: 3,
}

const PRODUCT_STOCK_CONFIG = {
  [PRODUCT_STOCK_CONST.MAJOR_SHORTAGE]: {
    title: "Major Shortage",
    param: "mediumShortage",
    color: "bg-red",
    isShow: true,
  },
  [PRODUCT_STOCK_CONST.MINOR_SHORTAGE]: {
    title: "Minor Shortage",
    param: "minorShortage",
    color: "bg-yellow",
    isShow: true,
  },
  [PRODUCT_STOCK_CONST.FULLSTOCK]: {
    title: "FullStock",
    param: "fullStock",
    color: "bg-green",
    isShow: true,
  },
}

const Dashboard = ({}) => {
  let history = useHistory()
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [isFetchingProductStock, setIsFetchingProductStock] = useState(false)
  const [productStock, setProductStock] = useState({})
  const [scrapReturnListing, setScrapReturnListing] = useState()

  // let wareHouseId = store.getState()?.userAuth.user.id || ""

  useEffect(() => {
    let handleDashboardAnalytics = async () => {
      try {
        setLoading(true)
        let res = await AdminFinanceDashboardAnalytics()
        setData(res)
      } catch (err) {
        toast.error(err.message)
      } finally {
        setLoading(false)
      }
    }

    handleDashboardAnalytics()
  }, [])

  let handleGetStockListing = async value => {
    if (!value) return

    let paramObj = {
      condition: value,
    }

    try {
      setIsFetchingProductStock(true)
      let res = await GetStockListing(paramObj)
      setProductStock(prev => ({ ...prev, data: res.data }))
    } catch (err) {
      toast.error(err.message)
    } finally {
      setIsFetchingProductStock(false)
    }
  }

  useEffect(() => {
    let scrapReturnListing = async () => {
      try {
        setLoading(true)
        let res = await GetScrapReturnListing()
        setScrapReturnListing(res)
      } catch (err) {
        toast.error(err.message)
      } finally {
        setLoading(false)
      }
    }

    scrapReturnListing()
  }, [])

  const handleSetProductStockConfig = obj => {
    setProductStock(obj)
    handleGetStockListing(obj.param)
  }

  const CONFIG_DATA = [
    {
      color: "bg-stock-red",
      data: data?.mediumShortage,
      categoryTitle: "Items",
      categoryDescription: "in Major Shortage",
      buttonTitle: "View Products",
      handleClick: () =>
        handleSetProductStockConfig(
          PRODUCT_STOCK_CONFIG[PRODUCT_STOCK_CONST.MAJOR_SHORTAGE]
        ),
    },
    {
      color: "bg-stock-yellow",
      data: data?.minorShortage,
      categoryTitle: "Items",
      categoryDescription: "in Minor Shortage",
      buttonTitle: "View Products",
      handleClick: () =>
        handleSetProductStockConfig(
          PRODUCT_STOCK_CONFIG[PRODUCT_STOCK_CONST.MINOR_SHORTAGE]
        ),
    },
    {
      color: " bg-stock-green",
      data: data?.fullStock,
      categoryTitle: "Items",
      categoryDescription: "in Full Stock",
      buttonTitle: "View Products",
      handleClick: () =>
        handleSetProductStockConfig(
          PRODUCT_STOCK_CONFIG[PRODUCT_STOCK_CONST.FULLSTOCK]
        ),
    },
  ]

  const handleCloseModal = () => {
    setProductStock({})
  }

  const handleScrapReturnRequest = () => {
    history.push({
      pathname: APP_ROUTES.ORDER_MANAGEMENT,
      state: "abcdef",
    })
  }

  const col_sm = 4

  return (
    <>
      <div className="dashboard-container">
        <Row>
          <Col lg={4} md={6} sm={12}>
            <AnalyticsBox
              className=" pt-2"
              title="Global Inventory"
              iconImage={globalinventory}
              data={data?.globalInventory}
              loading={loading}
            />
          </Col>
          <Col lg={4} md={6} sm={12}>
            <AnalyticsBox
              className=" pt-2"
              title="Warehouse Inventory"
              iconImage={warehouseinventory}
              data={data?.warehouseGlobalInventory}
              loading={loading}
            />
          </Col>
          <Col lg={4} md={12} sm={12}>
            <AnalyticsBox
              className=" pt-2"
              title="Departments Inventory"
              iconImage={departmentInventory}
              data={data?.departmentGlobalInventory}
              loading={loading}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <AnalyticsBox
              className=" pt-2"
              title="Product Stock"
              config={CONFIG_DATA}
              loading={loading}
            />
          </Col>
        </Row>

        <Col sm={12}>
          <Card>
            <CardBody>
              <div className="d-flex align-items-center justify-content-center">
                <h5 className="fw-600 mb-0 dh-srap-return-header">
                  SCRAP / RETURN REQUESTS
                </h5>
              </div>
              <DataTable
                data={getLimitedItems(scrapReturnListing?.data, 0, 3)}
                className="mt-3"
                tableClasses="ad-dashboard-table"
                config={[
                  {
                    title: COLUMNS.SKU_NO,
                    className: "black-color  ",
                    render: data => data?.skuNo || "-",
                  },
                  {
                    title: COLUMNS.ITEM_NAME,
                    className: "",
                    render: data => data?.itemName || "-",
                  },
                  {
                    title: COLUMNS.TYPE,
                    className: "",
                    render: data => data?.type || "-",
                  },
                  {
                    title: COLUMNS.QUANTITY,
                    className: "",
                    render: data => data?.quantity || "-",
                  },
                  {
                    title: COLUMNS.ISSUES,
                    className: "",
                    render: data =>
                      data?.issue || (
                        <div className="text-elipse">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s
                        </div>
                      ),
                  },
                  {
                    title: COLUMNS.DATE_OF_REQUEST,
                    className: "",
                    render: data =>
                      convertDateTime({
                        date: data?.createdAt,
                        customFormat: dateTimeFormat.casitaCutomDateFormat,
                        dateOnly: true,
                      }),
                  },
                ]}
                loading={false}
              />
            </CardBody>
          </Card>
        </Col>
      </div>

      <Modal
        isOpen={productStock?.isShow}
        handleModalToggling={handleCloseModal}
        bodyClassName="p-0 pb-2"
        customButton={true}
        hideModalHeaderSeparator={true}
        headerClasses="header-title-modal"
        size="md"
        backdrop="static"
        modalTitle={productStock?.title}
      >
        <ItemListing
          productStockData={productStock}
          isFetchingProductStock={isFetchingProductStock}
        />
      </Modal>
    </>
  )
}

export default Dashboard
