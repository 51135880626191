import React, { useRef, useState } from "react"
import uploadClickIcon from "assets/images/icons/upload-click-icon.png"
import modalCloseIcon from "../../../assets/images/inventory-request/modal-close-icon.png"
import uploadIcon from "../../../assets/images/finance/upload-icon.png"

const FinanceImageUpload = ({
  // image,
  onChange,
  // isNewImageSet = false,
  // setImageUploaded,
  // dumyimage,
  fileName,
  imageError,
  removeImageHandler
}) => {
  const profileFileUpload = useRef(null)
  const [imageUploaded, setImageUploaded] = useState(null)
  // const handleFileUploadClick = () => {
  //   profileFileUpload.current.click()
  // }

  // const imageHandler = e => {
  //   setImageUploaded(false)
  // }

  const handleChange = (event) => {
    setImageUploaded(event.target.files[0])
  }
  console.log('dd', imageUploaded)
  return (
    <>
    <div className="">
      <input 
      type="file" 
      name="file" 
      id="my-file" 
      style={{"display":"none"}} 
      onChange={onChange} 
      />
      <label htmlFor="my-file" className="place-order-image-con d-flex justify-content-center align-items-center">
      <img className="me-2" src={uploadIcon} alt="upload icon"/>
      <span >Upload Invoice</span>
      </label>
    </div>

      {imageError && 
      (<span className="d-block text-danger file-upload-error">{imageError}</span>)
      }
      {fileName && 
      (
      <div className="d-flex justify-content-between align-items-center">
        <span className="d-block img-upload-name">{fileName}</span>
        <div className="div-container">
          <button
            className="custom-btn-close-popup "
            onClick={removeImageHandler}
            type="button"
          >
            <img src={modalCloseIcon} />
          </button>
        </div>
        </div>)
      }
    </>
    // <div className="image-upload-container">
    //   {!isNewImageSet && (
    //     <div
    //       role="button"
    //       onClick={handleFileUploadClick}
    //       className="avatar-edit-icon bg-white rounded-circle d-inline-flex position-relative"
    //     >
    //       <div className="d-flex p-2">
    //         <div style={{ width: "15px", height: "19px" }}>
    //           <img
    //             className="clr-theme-primary w-100 h-100"
    //             src={uploadClickIcon}
    //           />
    //         </div>

    //         <p className="pl-2">Upload Invoice</p>
    //       </div>

    //       <input
    //         id="profilePicUpload"
    //         ref={profileFileUpload}
    //         type="file"
    //         hidden
    //         onChange={onChange}
    //         accept="image/*"
    //       />
    //     </div>
    //   )}
    //   {isNewImageSet && (
    //     <>
    //       <div className="div-container">
    //         <button
    //           className="custom-btn-close-popup "
    //           onClick={imageHandler}
    //           type="button"
    //         >
    //           <img src={modalCloseIcon} />
    //         </button>
    //       </div>
    //     </>
    //   )}
    //   {isNewImageSet ? (
    //     <>
    //       <div className="d-flex justify-content-center align-items-center container">
    //         <img src={image} className="imageHandle" />
    //       </div>
    //     </>
    //   ) : (
    //     <>
    //       <div className="d-flex justify-content-center align-items-center container">
    //         <img src={dumyimage} width={100} height={100} />
    //       </div>
    //     </>
    //   )}
    // </div>
  )
}

export default FinanceImageUpload