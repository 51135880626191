import React, { useState } from "react"
import { Row, Col } from "reactstrap"
import { Pagination } from "components/Pagination"
import { DataTable } from "components/DataTable"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"

import { PlacedOrdersOMListing } from "api/api.service"
import { SearchBox } from "components/SearchBox"
import { dateTimeFormat, convertDateTime } from "../../../utils/dateFormatUtils"
import { OrderManagementStatus } from "constants"
import { Modal } from "components/Modal"
import PlacedOrderDetailView from "./PlacedOrdersDetailView"
import ActionEyeIcon from "assets/images/icons/feather-eye-icon.png"

const COLUMNS = {
  Pono: "PO No.",
  VENDOR: "Vendor",
  DATE_OF_PLACING_ORDER: "Date of Placing Order",
  ORDER_PRICE: "Order Price",
  STATUS: "Status",
  ACTIONS: "Action",
}

const dataKey = "data"
const totalKey = "total"

const PlacedOrdersListing = () => {
  const [modalState, setModalState] = useState(false)
  const [PODataList, setPODataList] = useState([])
  const [dataRefetch, setDataRefetch] = useState(false)
  const [filters, setFilters] = useState(null)
  const {
    data,
    isFetching,
    page,
    handlePageClick,
    searchText,
    handleSearchChange,
    pageSize,
    onChangePageSize,
    total,
  } = useLocalPaginatedRequest({
    requestFn: PlacedOrdersOMListing,
    params: {
      ...(filters?.Status && { Status: filters?.Status }),
    },
    deps: [dataRefetch, filters],
    dataKey: dataKey,
    totalKey: totalKey,
  })

  return (
    <>
      <div>
        <Row>
          <Col>
            <div className="d-flex justify-content-end align-items-center">
              <div className="fm-search-input finance-byitems-search-ip">
                <SearchBox
                  searchText={searchText}
                  onChange={handleSearchChange}
                  inputClasses="table-main-search-input fm-table-input "
                  labelClass="d-none"
                  placeholder="Search"
                  searchIcon={true}
                  searchIconClass="fm-table-search-icon"
                  conClass="fm-table-search-con"
                />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <DataTable
              data={data}
              className="fm-table-con mt-3"
              tableClasses="finance-om-orderplaced-table"
              loading={isFetching}
              config={[
                {
                  title: COLUMNS.Pono,
                  className: "black-color fm-ftwg-600",
                  render: data => data?.poNo,
                },
                {
                  title: COLUMNS.VENDOR,
                  render: data => data?.vendorName,
                },
                {
                  title: COLUMNS.DATE_OF_PLACING_ORDER,
                  render: data => {
                    return convertDateTime({
                      date: data.createdAt,
                      customFormat: dateTimeFormat.casitaCutomDateFormat,
                      dateOnly: true,
                    })
                  },
                },
                {
                  title: COLUMNS.STATUS,
                  render: data => {
                    return (
                      <div
                        className={`fm-om-status ${OrderManagementStatus.getLabelClass(
                          data?.requestStatus
                        )}`}
                      >
                        {OrderManagementStatus.getDisplayTextKey(
                          data?.requestStatus
                        )}
                      </div>
                    )
                  },
                },              
                {
                  className: "text-center",
                  title: COLUMNS.ACTIONS,
                  render: data => {
                    return (
                      <span
                        className="text-success pointer"
                        onClick={() => {
                          setPODataList(data)
                          setModalState(true)
                        }}
                      >
                        <img src={ActionEyeIcon} />
                      </span>
                    )
                  },
                },
              ]}
            />
          </Col>
        </Row>
        <Pagination
          currentPage={page}
          totalData={total}
          onPageClick={handlePageClick}
        />
        <Modal
          isOpen={modalState}
          handleModalToggling={setModalState}
          bodyClassName="p-0"
          customButton={true}
          hideModalHeaderSeparator={true}
          headerClasses="pb-0"
          className="inv-req-popup-viewdetails"
          size="xl"
          sizeClasses="4"
        >
          {PODataList.orders && (
            <PlacedOrderDetailView
              modalCloseHandler={setModalState}
              data={PODataList}
              title="View Order"
              footerBtnType=""
              isPONO={true}
            />
          )}
        </Modal>
      </div>
    </>
  )
}

export default PlacedOrdersListing
