import React, { Fragment, useEffect, useRef } from "react"
import { Link, useHistory } from "react-router-dom"
import { Col, DropdownMenu, Row } from "reactstrap"
import SimpleBar from "simplebar-react"

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../../assets/images/users/avatar-4.jpg"
import boxIcon from "../../../assets/images/box-icon.svg"
import briefCaseIcon from "../../../assets/images/briefcase-icon.svg"
import giftIcon from "../../../assets/images/gift-icon.svg"

import { useInfiniteScrollRequest } from "hooks/useInfiniteScrollRequest"
import { GetNotifications } from "api/api.service"
import { Loader } from "components/Loader"
import moment from "moment"
import { NotificationEntityType } from "constants"
import { APP_ROUTES, getRoute } from "helpers/routeHelpers"
import { markNotificationsAsRead, setNotificationEntityId } from "store/actions"
import { useDispatch } from "react-redux"
import { NotificationReadStatus } from "constants"

const dataKey = "Notifications"
const totalKey = "TotalNotifications"

const NotiticationsList = ({ closeMenu = () => {} }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { isFetching, handleScroll, data } = useInfiniteScrollRequest({
    requestFn: GetNotifications,
    dataKey: dataKey,
    totalKey: totalKey,
  })

  const handleNotificationClick = notification => {
    let { EntityType, EntityId } = notification

    if (EntityType === NotificationEntityType.BUSINESS_ACCOUNT) {
      history.push({
        pathname: getRoute(APP_ROUTES.BUSINESS_ACCOUNTS_DETAILS, {
          id: EntityId,
        }),
      })
    } else if (EntityType === NotificationEntityType.ORDER) {
      history.push({
        pathname: getRoute(APP_ROUTES.ORDER_STATUS),
      })
      dispatch(setNotificationEntityId(EntityId))
    } else if (EntityType === NotificationEntityType.BOX) {
      history.push({
        pathname: getRoute(APP_ROUTES.BOXES),
      })
      dispatch(setNotificationEntityId(EntityId))
    }
    if (
      EntityType === NotificationEntityType.BUSINESS_ACCOUNT ||
      EntityType === NotificationEntityType.ORDER ||
      EntityType === NotificationEntityType.BOX
    ) {
      if (notification?.ReadStatus === NotificationReadStatus.UNREAD) {
        dispatch(
          markNotificationsAsRead({
            NotificationIds: [notification?.Id],
          })
        )
      }
      closeMenu()
    }
  }

  return (
    <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
      <div className="p-3">
        <Row>
          <Col xs={12}>
            <div className="fs-15 fw-600 clr-gray-dark text-center">
              Notifications
            </div>
          </Col>
        </Row>
      </div>

      {data && !!data.length ? (
        <div
          className="gt-notification-wrap scrolling-container"
          onScroll={handleScroll}
        >
          {data.map((item, index) => (
            <Fragment key={index}>
              <div className="sep-1px my-0"></div>
              <div
                className="text-reset notification-item pointer"
                onClick={() => {
                  handleNotificationClick(item)
                }}
              >
                <div className="d-flex">
                  <div className="col-2">
                    <img src={briefCaseIcon} className="img-fluid" />
                  </div>
                  <div
                    className={`fs-13 clr-gray-dark col-8 ${
                      item?.ReadStatus === NotificationReadStatus.UNREAD
                        ? "clr-blue-2"
                        : ""
                    }`}
                  >
                    {item?.Description || item?.Title}
                  </div>
                  <div className="fw-600 clr-black fs-10 col-2 text-end">
                    <div
                      className={`${
                        item?.ReadStatus === NotificationReadStatus.UNREAD
                          ? "clr-blue-2"
                          : ""
                      }`}
                    >
                      {moment(item?.CreatedAt).format("DD MMM, YYYY")}
                    </div>
                    {/* <div>Today</div> */}
                  </div>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      ) : (
        <div className="sep-1px my-0"></div>
      )}
      {!data.length && !isFetching && (
        <div className="fs-13 my-3 text-center">No Notifications</div>
      )}
      {isFetching && <Loader size="sm" classes="my-3" />}
    </DropdownMenu>
  )
}

export default NotiticationsList
