import React, { useEffect, useState } from "react"
import { CardBody, Col, Form, FormGroup, Label, Row } from "reactstrap"
import { useFormik } from "formik"

import { Button } from "components/Button"
import { TextInput } from "components/TextInput"
import { initialValues, validationSchema } from "./FormConfig"
import { AutoComplete } from "components/AutoComplete"
import { AutoCompleteStyling } from "pages/Common/AutoCompleteStyling"
import { UserRoles } from "constants"

const USER_ROLE_OPTIONS = [
  {
    label: "Executive",
    value: UserRoles.Executive,
  },
  {
    label: "Production Head",
    value: UserRoles.Production,
  },
  {
    label: "Department Head",
    value: UserRoles.Department,
  },
  {
    label: "Finance Manager",
    value: UserRoles.Finance,
  },
  {
    label: "Warehouse Manager",
    value: UserRoles.Warehouse,
  },
]

const AddEditUser = ({
  firstName = "",
  lastName = "",
  emailAddress = "",
  role = "",
  btnText = "",
  isAddUser = false,
  isEditUser = false,
  loading = false,
  onSubmitForm = () => {},
}) => {
  const [selectedRole, setSelectedRole] = useState("")

  useEffect(() => {
    let obj = USER_ROLE_OPTIONS.find(item => item.value == role)
    if (isEditUser) {
      formik.setFieldValue("firstName", firstName)
      formik.setFieldValue("lastName", lastName)
      formik.setFieldValue("emailAddress", emailAddress)
      formik.setFieldValue("role", role)
      setSelectedRole({
        ...obj,
      })
    }

    return () => {
      formik.setFieldValue("firstName", "")
      formik.setFieldValue("lastName", "")
      formik.setFieldValue("emailAddress", "")
      formik.setFieldValue("role", "")
    }
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: values => {
      handleAddEditUserSubmit(values)
    },
  })

  const handleSelectRole = option => {
    formik.setFieldValue("role", option.value)
    setSelectedRole(option)
  }

  const handleAddEditUserSubmit = values => {
    onSubmitForm(values)
  }

  return (
    <Form onSubmit={formik.handleSubmit}>
      <CardBody className="user-add-edit-card-container shadow-none">
        <FormGroup className="d-flex user-validation-handler">
          <Label for="first name" className="user-mang-modal-label " sm={4}>
            First Name
          </Label>
          <Col sm={7} className="input-text-container ">
            <TextInput
              className="user-mang-modal-input "
              type="first name"
              name="firstName"
              id="first name"
              placeholder="Type first name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
            />
          </Col>
        </FormGroup>

        <Row className="mb-3">
          <Col sm={4}></Col>
          <Col sm={7}>
            {formik.touched.firstName && formik.errors.firstName ? (
              <span className="error fs-10">{formik.errors.firstName}</span>
            ) : null}
          </Col>
        </Row>

        <FormGroup className="d-flex user-validation-handler">
          <Label for="last name" className="user-mang-modal-label " sm={4}>
            Last Name
          </Label>
          <Col sm={7} className="input-text-container ">
            <TextInput
              className="user-mang-modal-input "
              type="last name"
              name="lastName"
              id="last name"
              placeholder="Type Last Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
            />
          </Col>
        </FormGroup>

        <Row className="mb-3">
          <Col sm={4}></Col>
          <Col sm={7}>
            {formik.touched.lastName && formik.errors.lastName ? (
              <span className="error fs-10">{formik.errors.lastName}</span>
            ) : null}
          </Col>
        </Row>

        <FormGroup className="d-flex user-validation-handler">
          <Label for="email address" className="user-mang-modal-label" sm={4}>
            Email Address
          </Label>
          <Col sm={7} className="input-text-container ">
            <TextInput
              className="user-mang-modal-input "
              type="email address"
              name="emailAddress"
              id="email address"
              placeholder="Type Email Address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.emailAddress}
              disabled={isEditUser}
            />
          </Col>
        </FormGroup>

        <Row className="mb-3">
          <Col sm={4}></Col>
          <Col sm={7}>
            {formik.touched.emailAddress && formik.errors.emailAddress ? (
              <span className="error fs-10">{formik.errors.emailAddress}</span>
            ) : null}
          </Col>
        </Row>

        <FormGroup className="d-flex user-validation-handler">
          <Label for="role" className="user-mang-modal-label" sm={4}>
            Role
          </Label>
          <Col sm={7} className="input-text-container ">
            <AutoComplete
              placeholder="Select Role"
              classNamePrefix="add-new-req"
              customStyles={AutoCompleteStyling}
              options={USER_ROLE_OPTIONS}
              isClearable={true}
              onChange={handleSelectRole}
              value={selectedRole}
              isSearchable={false}
            />
          </Col>
        </FormGroup>

        <Row className="mb-3">
          <Col sm={4}></Col>
          <Col sm={7}>
            {formik.touched.role && formik.errors.role ? (
              <span className="error fs-10">{formik.errors.role}</span>
            ) : null}
          </Col>
        </Row>

        <Button
          type="submit"
          title={btnText}
          className="gt-btn-grad-primary mt-5 button-width-container"
          isLoading={loading}
        />
      </CardBody>
    </Form>
  )
}

export default AddEditUser
