import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Row, Col } from "reactstrap"
import { Pagination } from "components/Pagination"
import { DataTable } from "components/DataTable"
import { TextInput } from "components/TextInput"
import TableInnerHeaderSearch from "../TableInnerHeaderSearch"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import {
  dateTimeFormat,
  convertDateTime,
} from "../../../../utils/dateFormatUtils"
import { Modal } from "components/Modal"
import PlaceOrderModal from "./PlaceOrderModal"
import { WarehouseInventoryListing } from "api/api.service"
import { AutoComplete } from "components/AutoComplete"
import { SearchBox } from "components/SearchBox"

import {
  updatePlaceOrderButton,
  CHECK_BOX_UPDATE_MODAL_ITEMS,
  UPDATE_SELECTED_ITEMS_LENGTH,
  updateSelectedItemsLength,
} from "store/actions"

import { OrderRequestByItemsListing } from "api/api.service"

import customStyles from "../HeaderStatusStyling"
import { updateObjCheckBox } from "../../../../utils/commonUtils"

const COLUMNS = {
  SELECT_ALL: "Select All",
  ORDER_ID: "Order No.",
  SKU: "SKU No.",
  ITEM_NAME: "Item Name",
  QUANTITY: "Requested Quantity",
  QUANTITY_REMANING: "Quantity Remaining",
  DATE_OR_REQUEST: "Requested Date",
}

const dataKey = "data"
const totalKey = "total"

const OrderRequestByItem = ({ sortBy, handleTogglerChange }) => {
  let modalState = useSelector(state => state.checkBoxSelect.updateCheckBoxData)
  const dispatch = useDispatch()
  const [modalDataList, setModalDataList] = useState([])
  const [isSelectAll, setIsSelectAll] = useState(false)
  const [dataRefetch, setDataRefetch] = useState(false)
  const [modalDependancy, setModalDependancy] = useState(false)
  const [filters, setFilters] = useState(null)
  const {
    data,
    isFetching,
    searchText,
    handleSearchChange,
    page,
    handlePageClick,
    pageSize,
    onChangePageSize,
    total,
  } = useLocalPaginatedRequest({
    requestFn: OrderRequestByItemsListing,
    params: {
      ...(filters?.Status && { Status: filters?.Status }),
    },
    deps: [dataRefetch, filters, modalDependancy],
    dataKey: dataKey,
    totalKey: totalKey,
  })

  let dataLength = data.length
  // DISPATCH SELECTED LIST ITEMS
  useEffect(() => {
    dispatch(updateSelectedItemsLength({ data: modalDataList.length }))
  }, [modalDataList.length])
  useEffect(() => {
    setIsSelectAll(false)
  }, [data])

  // data update for modal on listing check/uncheck items
  const handleCheckBoxSelect = (e, item) => {
    // item.requiredQuantity = 0
    const dataTemp = updateObjCheckBox(item, modalDataList)
    let filteredData = data.filter(el => el.quantity - el.quantityreceived > 0)
    isSelectAll &&
      dataTemp.length !== filteredData.length &&
      setIsSelectAll(false)
    !isSelectAll &&
      dataTemp.length === filteredData.length &&
      setIsSelectAll(true)
    setModalDataList(dataTemp)
  }
  // select all handler
  const handleSelectAll = () => {
    let filteredData = data.filter(el => el.quantity - el.quantityreceived > 0)
    isSelectAll ? setModalDataList([]) : setModalDataList(filteredData)
    setIsSelectAll(!isSelectAll)
  }

  // place order modal close
  const placeOrderModalClose = () => {
    setModalDataList([])
    setIsSelectAll(false)
    dispatch(updatePlaceOrderButton({ type: CHECK_BOX_UPDATE_MODAL_ITEMS }))
  }

  // API hit on modal submit data
  const modalDependancyHandler = () => {
    setModalDependancy(!modalDependancy)
  }

  return (
    <>
      <div className="fm-itemsorders-toggle-right-con mb-3">
        <div className="finance-orderbyitems-autocomplete">
          <div className="fm-search-input finance-byitems-search-ip">
            <SearchBox
              searchText={searchText}
              onChange={handleSearchChange}
              inputClasses="table-main-search-input fm-table-input"
              labelClass="d-none"
              placeholder="Search"
              searchIcon={true}
              searchIconClass="fm-table-search-icon"
              conClass="fm-table-search-con"
            />
          </div>
        </div>
      </div>
      <DataTable
        data={data}
        className="fm-table-con "
        tableClasses="ad-mang-order-request-table"
        loading={isFetching}
        config={[
          {
            title: COLUMNS.ORDER_ID,
            className: "black-color fm-ftwg-600",
            render: data => data?.warehouseRequestId,
          },
          {
            title: COLUMNS.SKU,
            className: "black-color fm-ftwg-600",
            render: data => data?.skuNo,
          },
          {
            title: COLUMNS.ITEM_NAME,
            render: data => data?.itemName,
          },
          {
            title: COLUMNS.QUANTITY,
            render: data => data?.quantity,
          },
          {
            title: COLUMNS.QUANTITY_REMANING,
            render: data => data?.quantity - data?.quantityreceived,
          },
          {
            title: COLUMNS.DATE_OR_REQUEST,
            render: data => {
              return convertDateTime({
                date: data.updatedAt,
                customFormat: dateTimeFormat.casitaCutomDateFormat,
                dateOnly: true,
              })
            },
          },
        ]}
      />
      <Pagination
        currentPage={page}
        totalData={total}
        onPageClick={handlePageClick}
      />
      <Modal
        isOpen={modalState}
        handleModalToggling={placeOrderModalClose}
        bodyClassName="p-0"
        customButton={true}
        hideModalHeaderSeparator={true}
        headerClasses="pb-0"
        className="inv-req-popup-viewdetails"
        size="xl"
        sizeClasses="4"
      >
        <PlaceOrderModal
          modalCloseHandler={placeOrderModalClose}
          data={modalDataList}
          title="Order Details"
          footerBtnType="Save Order Details"
          dependancyAPIHandler={modalDependancyHandler}
        />
      </Modal>
    </>
  )
}

export default OrderRequestByItem
