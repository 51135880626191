import React, { useState } from "react"

import { Button } from "components/Button"
import { DataTable } from "components/DataTable"
import {
  Row,
  Col,
} from "reactstrap"
import { PlacedOrderStatusOM } from "constants"
import { FinanceImageUpload } from "../../../Common/ImageUpload"
import {
  dateTimeFormat,
  convertDateTime,
} from "../../../../utils/dateFormatUtils"
import { arrayItemsInList } from "../../../../utils/commonUtils"

const COLUMNS = {
  PONO: "PO No.",
  SELECT_ALL: "Select All",
  ORDER_ID: "Order No.",
  SKU: "SKU No.",
  VENDOR_NAME: "Vendor",
  ITEM_NAME: "Item Name",
  REQUESTED_QUANTITY: "Requested Quantity",
  UNIT_PRICE: "Unit Price",
  TOTAL_PRICE: "Total Price",
  STATUS: "Status",
  QUANTITY_SUPPLIED: "Supplied Quantity",
  DATE_OF_RECEIVED_ITEMS: "Date of Received Item",
  SUBSITUTE_ITEM: "Subsitute Name",
  ACTION: "Action",
}

const OMViewDetailModal = ({
  data,
  title,
  footerBtnType,
  modalCloseHandler,
}) => {

  return (
    <>
      <h3 className="text-left fm-req-popup-heading mb-0 px-5 mb-5">{title}</h3>
      <div className="pt-3 px-5">
        <Row>
          <Col sm="6" xs="12" md="3" lg="3" className="my-auto">
            <p className="place-order-top-date my-auto">
              Order No. : <span>{data?.orderNo}</span>
            </p>
          </Col>
          <Col sm="6" xs="12" md="5" lg="5" className="my-auto">
            <p className="place-order-top-date my-auto">
              Date of Order Placed:{" "}
              <span>
                {convertDateTime({
                  date: data.createdAt,
                  customFormat: dateTimeFormat.casitaCutomDateFormat,
                  dateOnly: true,
                })}
              </span>
            </p>
          </Col>
          <Col sm="12" xs="12" md="12" lg="12" className="px-0 mb-4 mt-3">
            <DataTable
              data={data && data.orders}
              className=" mt-3 fm-place-order-table place-order-data-table finance-vertical-scroll-om po-data-table-finance"
              tableClasses="finance-order-management-order-by-order-modal-table"
              config={[
                {
                  title: COLUMNS.PONO,
                  className: "table-column-bold",
                  render: data => arrayItemsInList(data?.poNo) || "-",
                },
                {
                  title: COLUMNS.SKU,
                  className: "table-column-bold",
                  render: data => data?.skuNo || "-",
                },
                {
                  title: COLUMNS.ITEM_NAME,
                  render: data => data?.itemName || "-",
                },
                {
                  title: COLUMNS.SUBSITUTE_ITEM,
                  render: data => data?.substitute?.itemName || "-",
                },
                {
                  title: COLUMNS.VENDOR_NAME,
                  render: data => arrayItemsInList(data?.vendorName) || "-",
                },

                {
                  title: COLUMNS.REQUESTED_QUANTITY,
                  className: "table-column-bold",
                  render: data => data?.quantity || "-",
                },
                {
                  title: COLUMNS.UNIT_PRICE,
                  className: "table-column-bold",
                  render: data =>
                    arrayItemsInList(data?.unitPrice, true) || "-",
                },
                {
                  title: COLUMNS.QUANTITY_SUPPLIED,
                  render: data => data?.quantitySupplied || "-",
                },
                {
                  title: COLUMNS.DATE_OF_RECEIVED_ITEMS,
                  render: data => {
                    return (
                      (data.createdAt !== "" &&
                        convertDateTime({
                          date: data.createdAt,
                          customFormat: dateTimeFormat.casitaCutomDateFormat,
                          dateOnly: true,
                        })) ||
                      "-"
                    )
                  },
                },
                {
                  title: COLUMNS.STATUS,
                  render: data => {
                    return (
                      <div
                        className={`place-order-status ${PlacedOrderStatusOM.getLabelClass(
                          data?.status
                        )}`}
                      >
                        {PlacedOrderStatusOM.getDisplayTextKey(data?.status) ||
                          "-"}
                      </div>
                    )
                  },
                },
              ]}
            />
          </Col>          
        </Row>
      </div>
      {footerBtnType !== "" && (
        <div className="text-center my-4 d-flex justify-content-center align-items-center">
          <Button
            size="lg"
            className="gt-btn-grad-primary btn-height me-2 d-flex justify-content-center align-items-center place-order-btn"
            title={<div>{footerBtnType}</div>}
            onClick={modalCloseHandler}
          />
        </div>
      )}
    </>
  )
}

export default OMViewDetailModal
