import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Container, Col, Row } from "reactstrap"

import { Header } from "../Common/Header"
import { NavTabs } from "../Common/NavTabs"

import OrderRequestsListing from "./OrderRequestsListing"
import PlacedOrdersListing from "./PlacedOrdersListing"
import ScrapReturnListing from "./ScrapReturnListing"
import {
  updatePlaceOrderButton,
  CHECK_BOX_UPDATE_MODAL_ITEMS,
} from "store/actions"

const OrderManagement = () => {
  const selectedData = useSelector(
    state => state.checkBoxSelect.selectedItemsListLength
  )
  const dispatch = useDispatch()
  // handle order request tab toggle
  const [sortBy, setSortBy] = useState(false)
  const [activeTabValue, setActiveTabValue] = useState("or1")

  // handle Place Order Button onClick
  const placeOrderClickHandler = () => {
    dispatch(updatePlaceOrderButton({ type: CHECK_BOX_UPDATE_MODAL_ITEMS }))
  }
  const buttonData = [
    {
      buttonTitle: "Place Order",
      clickHandler: placeOrderClickHandler,
      className: "me-2",
      size: "lg",
      status: true,
      disabledVar: selectedData,
    },
  ]

  const navItemsData = [
    {
      identifier: "or1",
      title: "Order Requests",
      content: (
        <OrderRequestsListing sortBy={sortBy} handleTogglerChange={setSortBy} />
      ),
    },
    {
      identifier: "po1",
      title: "Placed Orders",
      content: <PlacedOrdersListing />,
    },
  ]

  return (
    <>
      <div>
        <Container fluid>
          <Row>
            <Col className="col-12">
              <Header
                headerTitle="Order Management"
                buttonData={buttonData}
                sortBy={sortBy}
                activeTabValue={activeTabValue}
                showButtonSearch={false}
              />
              <NavTabs
                navData={navItemsData}
                defaultActiveTab="or1"
                navtabClasses="fm-navtabs-main mb-3"
                setActiveTabValue={setActiveTabValue}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default OrderManagement
