import React, { useState } from "react"
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap"
import classnames from "classnames"

const NavTabs = props => {
  const {
    navData,
    defaultActiveTab,
    navtabClasses = "",
    setActiveTabValue = () => {},
    route,
  } = props
  const [activeTabSecond, setActiveTabSecond] = useState(defaultActiveTab)

  const toggle = tab => {
    if (activeTabSecond !== tab) {
      setActiveTabSecond(tab)
      setActiveTabValue(tab)
    }
  }

  return (
    <>
      <div className="fm-nav-tabs-border">
        <Nav tabs className={`border-0 ${navtabClasses}`}>
          {navData &&
            navData.map(el => {
              return (
                <NavItem key={el.identifier}>
                  <NavLink
                    className={classnames({
                      active: activeTabSecond === el.identifier,
                    })}
                    onClick={() => {
                      toggle(el.identifier)
                    }}
                  >
                    {el.title}
                  </NavLink>
                </NavItem>
              )
            })}
        </Nav>
        <TabContent activeTab={activeTabSecond}>
          {navData &&
            navData.map(el => {
              return (
                <TabPane tabId={el.identifier} key={el.identifier}>
                  <Row>
                    <Col sm="12">
                      {activeTabSecond == el.identifier && el.content}
                    </Col>
                  </Row>
                </TabPane>
              )
            })}
        </TabContent>
      </div>
    </>
  )
}

export default NavTabs
