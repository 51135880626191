import React, { useState } from "react"
import { Col, Row } from "reactstrap"
import toast from "react-hot-toast"

import { Pagination } from "components/Pagination"
import { DataTable } from "components/DataTable"
import financeediticon from "../../../../assets/images/icons/finance-vendor-mang-edit-icon.svg"
import { Modal } from "components/Modal"
import AddEditModal from "../modals/AddEditModal"
import DeleteModal from "../modals/DeleteModal"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import {
  WarehouseListing,
  AddWarehouse,
  UpdateWarehouse,
  DeleteWarehouse,
} from "api/api.service"
import { Header } from "../../Common/Header"
import { truncateString } from "utils/commonUtils"

const COLUMNS = {
  S_NO: "S.No.",
  DEPARMENT_NAME: "Name of the Warehouse",
  ADDRESS: "Address",
  LOCATION: "Location",
  ACTIONS: "Actions",
}

const MODAL_ACTIONS = {
  ADD: "1",
  EDIT: "2",
  DELETE: "3",
}

const dataKey = "data"
const totalKey = "total"
const CHARACTER_LIMIT = 20

const Warehouse_Location_Listing = ({}) => {
  const [actionStates, setActionStates] = useState({
    isAddWarehouse: null,
    isEditWarehouse: null,
    isDeleteWarehouse: null,
  })
  const [currentActionKey, setCurrentActionKey] = useState(null)
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isDataUpdated, setIsDataUpdated] = useState(false)
  const [recordDataID, setRecordDataID] = useState(undefined)
  const [filters, setFilters] = useState(null)
  const {
    data,
    isFetching,
    page,
    searchText,
    pageSize,
    request,
    handleSearchChange,
    onChangePageSize,
    handlePageClick,
    total,
  } = useLocalPaginatedRequest({
    requestFn: WarehouseListing,
    params: {
      ...(filters?.Status && { Status: filters?.Status }),
    },
    deps: [filters, isDataUpdated],
    dataKey: dataKey,
    totalKey: totalKey,
  })

  const handleEditWarehouse = data => {
    setSelectedRowData(data)
    setCurrentActionKey(MODAL_ACTIONS.EDIT)
    setActionStates(prev => ({ ...prev, isEditWarehouse: true }))
  }

  let currentAction = {
    [MODAL_ACTIONS.ADD]: {
      title: "Add Warehouse",
      btnText: "Add Warehouse",
    },
    [MODAL_ACTIONS.EDIT]: {
      title: "Edit Warehouse",
      btnText: "Edit Warehouse",
    },
    [MODAL_ACTIONS.DELETE]: {
      title: "Delete Warehouse",
      btnText: "Delete Warehouse",
      value: "Location",
    },
  }

  const handleCloseModal = () => {
    setActionStates({
      isAddWarehouse: null,
      isEditWarehouse: null,
      isDeleteWarehouse: null,
    })
    setCurrentActionKey(null)
  }

  const handleAddWarehouse = () => {
    setSelectedRowData(null)
    setCurrentActionKey(MODAL_ACTIONS.ADD)
    setActionStates(prev => ({ ...prev, isAddWarehouse: true }))
  }

  const handleWarehouseLocationSubmit = values => {
    let { name, address, location, userId } = values

    let formBody = {
      warehouseName: name,
      address,
      location,
      userId: currentActionKey == "2" ? selectedRowData.userId : userId,
      status: "ACTIVE",
    }
    handleSumbitForm(formBody)
  }

  const handleSumbitForm = async formBody => {
    try {
      setIsLoading(true)
      let response =
        currentActionKey == "2"
          ? await UpdateWarehouse(selectedRowData.id, formBody, false)
          : await AddWarehouse(formBody, false)
      updateAPISuccess()
      toast.success("Success !")
      request()
      handleCloseModal()
    } catch (err) {
      toast.error(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteRecordHandler = async () => {
    try {
      setIsLoading(true)
      let response = await DeleteWarehouse(recordDataID)
      updateAPISuccess()
      toast.success("Warehouse has been deleted successfully!")
      request()
      handleCloseModal()
    } catch (err) {
      toast.error(err.message)
    } finally {
      setIsLoading(false)
    }
  }
  // update on API submit
  const updateAPISuccess = () => setIsDataUpdated(!isDataUpdated)

  return (
    <>
      <Row>
        <Col>
          <Header
            btnTitle="Add New Warehouse"
            onClick={handleAddWarehouse}
            searchText={searchText}
            onChangeHandler={handleSearchChange}
            flexResponsive="location-flex-responsiveness"
            searchResponsiveness="location-searchResponsiveness"
            flexResponsiveInner="location-flexResponsiveInner"
            headerBtnResponsive="ad-location-header-btn-responsive"
            SearchBoxContainer="admin-location-search-box-container"
          />
        </Col>
        <Col md={12}>
          <DataTable
            data={data}
            loading={isFetching}
            className="location-data-container mt-3"
            tableClasses="ad-location-table"
            config={[
              {
                title: COLUMNS.S_NO,
                className: "black-color fm-ftwg-600  ",
                render: data => data?.id || "-",
              },
              {
                title: COLUMNS.DEPARMENT_NAME,
                className: "",
                render: data => data?.warehouseName || "-",
              },
              {
                title: COLUMNS.ADDRESS,
                className: "",
                render: data => {
                  if (data?.address?.length > CHARACTER_LIMIT) {
                    return (
                      <div data-container={data?.address}>
                        {truncateString(data?.address, CHARACTER_LIMIT)}
                      </div>
                    )
                  } else {
                    return data?.address || "-"
                  }
                },
              },
              {
                title: COLUMNS.LOCATION,
                className: "",
                render: data => data?.location || "-",
              },
              {
                title: COLUMNS.ACTIONS,
                className: "",
                render: data => (
                  <div className="d-flex justify-content-between location-icon-container">
                    <span
                      className=" pointer "
                      onClick={e => handleEditWarehouse(data)}
                    >
                      <img src={financeediticon} />
                    </span>
                  </div>
                ),
              },
            ]}
          />
        </Col>
      </Row>
      <Pagination
        currentPage={page}
        totalData={total}
        onPageClick={handlePageClick}
      />

      <Modal
        isOpen={!!currentAction[currentActionKey]}
        handleModalToggling={handleCloseModal}
        bodyClassName="p-0"
        customButton={true}
        hideModalHeaderSeparator={true}
        headerClasses="modal-header-container"
        size="md"
        modalTitle={currentAction[currentActionKey]?.title}
      >
        {(actionStates.isAddWarehouse || actionStates.isEditWarehouse) && (
          <AddEditModal
            btnText={currentAction[currentActionKey].btnText}
            activeModalAction={currentActionKey}
            data={selectedRowData}
            onSubmitForm={handleWarehouseLocationSubmit}
            isLoading={isLoading}
            type="WAREHOUSE"
          />
        )}
        {actionStates.isDeleteWarehouse && (
          <DeleteModal
            handleModalClose={handleCloseModal}
            value="Warehouse"
            handleDeleteRecord={deleteRecordHandler}
          />
        )}
      </Modal>
    </>
  )
}

export default Warehouse_Location_Listing
