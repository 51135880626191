import React from "react"
import { Row, Col, Input } from "reactstrap"

import FormikErrorText from "components/FormikErrorText/FormikErrorText"
import { preventUnwantedInput } from "utils/formUtils"

const ItemThresholdSection = ({ formik = {}, isReadOnly = false }) => {
  return (
    <Col sm={10} className="px-0 px-md-0 stock-status-data">
      <Row>
        <Col md="12" sm="12" className="px-3 px-md-0 ms-2 ms-md-0">
          <div className="d-flex align-items-center mb-3 me-2">
            <div className="color-box bg-medium-red me-2 ms-2 ms-md-1 ms-lg-2"></div>
            <div className="text-right mx-2 min-width-70 addnewitem-typo">
              0 to
            </div>
            <div className="d-flex flex-column ">
              <Input
                disabled={isReadOnly}
                className="py-1 addnewitem-modal-color-input me-2 ps-2 stock-status-input"
                name="minorThreshold"
                onChange={formik.handleChange}
                onBlur={formik.handleChange}
                value={formik.values.minorThreshold}
              />
              <FormikErrorText
                formikInstance={formik}
                fieldName={"minorThreshold"}
                errorClasses="fs-10 ms-2"
              />
            </div>
            <div className="addnewitem-typo ps-2">Major Shortage</div>
          </div>

          <div className="d-flex align-items-center mb-3 me-2">
            <div className="color-box bg-medium-orange me-2 ms-2 ms-md-1 ms-lg-2"></div>
            <div className="text-right mx-2 min-width-70 addnewitem-typo">
              {formik.values.minorThreshold || "-"} to
            </div>
            <div className="d-flex flex-column ">
              <Input
                onKeyDown={e => preventUnwantedInput(e)}
                className="py-1 addnewitem-modal-color-input me-2 ps-2 stock-status-input"
                name="mediumThreshold"
                onChange={formik.handleChange}
                onBlur={formik.handleChange}
                value={formik.values.mediumThreshold}
                disabled={!formik.values.minorThreshold || isReadOnly}
              />
              <FormikErrorText
                formikInstance={formik}
                fieldName={"mediumThreshold"}
                errorClasses="fs-10 ms-2"
              />
            </div>
            <div className="addnewitem-typo ps-2">Minor Shortage</div>
          </div>

          <div className="d-flex align-items-center mb-3 me-2">
            <div className="color-box bg-medium-green me-2 ms-2 ms-md-1 ms-lg-2"></div>
            <div className="text-right mx-2 min-width-70 addnewitem-typo">
              more than
            </div>
            <div className="d-flex flex-column ">
              <Input
                onKeyDown={e => preventUnwantedInput(e)}
                className="py-1 addnewitem-modal-color-input me-2 ps-2 stock-status-input"
                name="majorThreshold"
                onChange={formik.handleChange}
                onBlur={formik.handleChange}
                value={formik.values.majorThreshold}
                disabled={!formik.values.mediumThreshold || isReadOnly}
              />

              <FormikErrorText
                formikInstance={formik}
                fieldName={"majorThreshold"}
                errorClasses="fs-10 ms-2"
              />
            </div>
            <div className="addnewitem-typo ps-2">Full Stock</div>
          </div>
        </Col>
      </Row>
    </Col>
  )
}

export default ItemThresholdSection
