import React, { useEffect } from "react"
import { CardBody, Col, Form, FormGroup, Label, Row } from "reactstrap"
import { useFormik } from "formik"

import { Button } from "components/Button"
import { TextInput } from "components/TextInput"
import { initialValues, validationSchema } from "./FormConfig"

const AddEditVendor = ({
  isAddVendor = false,
  isEditVendor = false,
  vendorName = "",
  contactNumber = "",
  btnText = "",
  onSubmitForm,
  loading,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: values => {
      handleAddEditVendorSubmit(values)
    },
  })

  useEffect(() => {
    if (isEditVendor) {
      formik.setFieldValue("fullName", vendorName)
      formik.setFieldValue("contactNumber", contactNumber)
    }
    return () => {
      formik.setFieldValue("fullName", "")
      formik.setFieldValue("contactNumber", "")
    }
  }, [])

  const handleAddEditVendorSubmit = values => {
    onSubmitForm(values)
  }

  return (
    <Form onSubmit={formik.handleSubmit}>
      <CardBody className="vendor-add-edit-card-container shadow-none">
        <FormGroup className="d-flex validation-handler ">
          <Label for="full name" className="modal-label " sm={4}>
            Full Name
          </Label>
          <Col sm={7} className="input-text-area">
            <TextInput
              className="modal-input"
              type="text"
              name="fullName"
              id="full name"
              placeholder="Type Full Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fullName}
            />
          </Col>
        </FormGroup>
        <Row className="mb-3">
          <Col sm={4}></Col>
          <Col sm={7}>
            {formik.touched.fullName && formik.errors.fullName ? (
              <span className="error fs-10">{formik.errors.fullName}</span>
            ) : null}
          </Col>
        </Row>

        <FormGroup className="d-flex validation-handler ">
          <Label for="contact number" className="modal-label " sm={4}>
            Contact Number
          </Label>
          <Col sm={7} className="input-text-area">
            <TextInput
              className="modal-input"
              type="text"
              name="contactNumber"
              id="contact number"
              placeholder="Type Contact Number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.contactNumber}
            />
          </Col>
        </FormGroup>
        <Row className="mb-3">
          <Col sm={4}></Col>
          <Col sm={7}>
            {formik.touched.contactNumber && formik.errors.contactNumber ? (
              <span className="error fs-10">{formik.errors.contactNumber}</span>
            ) : null}
          </Col>
        </Row>
        <Button
          type="submit"
          title={btnText}
          className="gt-btn-grad-primary mt-4 button-width-vendor"
          isLoading={loading}
        />
      </CardBody>
    </Form>
  )
}

export default AddEditVendor
