import React, { useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  FormFeedback,
  Form,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import logoImage from "../../assets/images/signin/Casita-WebLogo.png"

import { withRouter, Link, Redirect, Route, Switch } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { TextInput } from "../../components/TextInput"
import { Button } from "../../components/Button"
import { APP_ROUTES } from "../../helpers/routeHelpers"

// import images
import profile from "assets/images/profile-img.png"
import logo from "../../assets/images/logo-circle.png"
import { login } from "store/actions/authActions"
import { withAuthentication } from "hoc"
import { UserTypes } from "constants"
import Login from "./Login"
import ForgetPassword from "./ForgetPassword"
import ForgetVerifyCode from "./ForgetVerifyCode"
import ResetPassword from "./ResetPassword"

const Auth = props => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .trim()
        .email("Please enter a correct email address")
        // .min(6, "Please enter an email address between 6 and 256 characters")
        // .max(256, "Please enter an email address between 6 and 256 characters")
        .required("Please enter your email address"),
      password: Yup.string().trim().required("Please enter your password"),
    }),
    onSubmit: async ({ email, password }) => {
      try {
        setLoading(true)
        const res = await dispatch(
          login({
            Email: email,
            Password: password,
            // Type: UserTypes?.ADMIN,
            Type: "admin",
          })
        )
      } catch (err) {
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <Container className="px-0 " fluid="xxl">
      {/* <Row className="justify-content-center"> */}
      <div className="limiter">
        <div className="container-login100 ">
          <div className="left-img-container login100-more d-none d-sm-block">
            <div className="mx-auto p-5 left-img-inner-container">
              <div className="left-img">
                <img src={logoImage} />
              </div>
              {/* <h5>Lorum Ipsum</h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                lobortis maximus nunc, ac rhoncus odio congue quis. Sed ac
                semper orci, eu porttitor lacus.{" "}
              </p> */}
            </div>
          </div>
          <Switch>
            <Route exact path={APP_ROUTES.LOGIN}>
              <Login />
            </Route>
            <Route exact path={APP_ROUTES.FORGET_PASSWORD}>
              <ForgetPassword />
            </Route>
            <Route exact path={APP_ROUTES.FORGET_VERIFY_CODE}>
              <ForgetVerifyCode />
            </Route>
            <Route exact path={APP_ROUTES.RESET_PASSWORD}>
              <ResetPassword />
            </Route>
          </Switch>
        </div>
      </div>
    </Container>
  )
}

export default withAuthentication(withRouter(Auth))
