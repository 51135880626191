import React from "react"
import { CardBody } from "reactstrap"

import { Button } from "components/Button"

const DeleteVendorModal = () => {
  return (
    <div className="d-flex flex-column align-items-center  mb-4 mt-4">
      <CardBody>
        <h5 className="modal-label">Are you want to delete this Vendor?</h5>
      </CardBody>
      <div className="d-flex justify-content-between mt-5">
        <Button
          type=""
          title="Cancel"
          className="vendor-modal-cancel-btn"

          // isLoading={null}
        />

        <Button
          type=""
          title="Delete"
          className="gt-btn-grad-primary vendor-modal-del-btn"
          // isLoading={null}
        />
      </div>
    </div>
  )
}

export default DeleteVendorModal
