import React from "react"
import { Button } from "components/Button"

const Header = ({ headerTitle, buttonData, sortBy, activeTabValue, headerTitleClass="", classPrefix="" }) => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center fm-main-heading-con">
        <h1 className={`fm-main-heading mb-0 ${headerTitleClass}`}>{headerTitle}</h1>
        <div className="d-flex justify-content-between align-items-center fm-main-heading-con">
        {
          buttonData.map((el, index) => {
            return(
              el.status && !sortBy && activeTabValue === "1"
              && 
                (<div key={index} className={`${classPrefix}-btn-con`}>
                <Button 
                  size={el.size}
                  className={`gt-btn-grad-primary fm-main-heading-btn ${el.className}`}
                  title={<div>{el.buttonTitle}</div>}
                  onClick={el.clickHandler}
                  disabled={el.disabledVar === 0}
                />
              </div>)
            )
          })}
        </div>
      </div>
    </>
  )
}

export default Header
