import React, { useState, useEffect } from "react"
import { Col, Row, Form, FormGroup, Label } from "reactstrap"

import ScrapImageUpload from "./ScrapImageUpload"
import dumyimage from "assets/images/image-upload-placeholder.png"
import { Button, GroupedButtons } from "components/Button"
import { getMediaPath } from "utils/mediaUtils"

const GROUPED_BTNS_CONFIG = [
  {
    color: "primary",
    isOutlined: true,
    activeId: "Scrap",
    classes: "btn-common",
    btnTitle: "Scrap",
    onClick: () => {},
  },
  {
    color: "primary",
    isOutlined: true,
    activeId: "Return",
    classes: "btn-common",
    btnTitle: "Return",
    onClick: () => {},
  },
]

const ScrapReturnForm = ({
  isReadOnly = true,
  data = null,
  closeModal = () => {},
}) => {
  const [imagePreview, setImagePreview] = useState(dumyimage)
  const [reqType, setReqType] = useState(null)
  const [sku, setSku] = useState("")
  const [itemName, setItemName] = useState("")
  const [quantity, setQuantity] = useState("")
  const [issue, setIssue] = useState("")

  useEffect(() => {
    if (data) {
      setIssue(data?.issue)
      setItemName(data?.itemName)
      setQuantity(data?.quantity)
      setSku(data?.skuNo)
      setReqType(data?.type)
      setImagePreview(getMediaPath(data?.image))
    }
  }, [data])

  const getContent = value => {
    if (isReadOnly && value) {
      return value
    } else if (isReadOnly && !value) {
      return "-"
    } else {
      return false
    }
  }

  return (
    <>
      <div>
        <h3 className="text-center">SCRAP / RETURN</h3>
        <div className="text-center mt-4 mb-4">
          <GroupedButtons
            isReadOnly={isReadOnly}
            handleSetActive={val => setReqType(val)}
            defaultActive={reqType}
            btnConfig={GROUPED_BTNS_CONFIG}
            btnContainerClasses="scrap-return-button-container"
          />
        </div>
        <Row>
          <Col sm="12" md="6" lg="6">
            <Form>
              {/* SKU  */}
              <FormGroup row>
                <Label
                  for="skuSelection"
                  sm={4}
                  align="right"
                  className="pl-0 pr-0"
                >
                  SKU No.
                </Label>

                <Col sm={8} className="pl-0 pr-0 d-flex align-items-center">
                  {getContent(sku)}
                </Col>
              </FormGroup>

              {/* Item Name */}

              <FormGroup row>
                <Label for="itemName" sm={4} align="right">
                  Item Name
                </Label>
                <Col sm={8} className="d-flex align-items-center">
                  {getContent(itemName)}
                </Col>
              </FormGroup>
              {/* Quantity */}
              <FormGroup row>
                <Label for="quantity" sm={4} align="right">
                  Quantity
                </Label>
                <Col
                  sm={8}
                  className="d-flex justify-content-center flex-column"
                >
                  {getContent(quantity)}
                </Col>
              </FormGroup>
              {/* Issue */}
              <FormGroup row>
                <Label for="issues" sm={4} align="right">
                  Issue
                </Label>
                <Col sm={8} className="d-flex align-items-center">
                  {getContent(issue)}
                </Col>
              </FormGroup>
            </Form>
          </Col>

          <Col sm="12" md="6" lg="6">
            <ScrapImageUpload
              image={imagePreview}
              isReadOnly={isReadOnly}
              dumyimage={dumyimage}
            />
          </Col>
        </Row>

        <div className="mt-3 text-center">
          <Button
            title={"Done"}
            className="gt-btn-grad-primary"
            onClick={closeModal}
          />
        </div>
      </div>
    </>
  )
}

export default ScrapReturnForm
