import React, { useState } from "react"
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
} from "reactstrap"

import DepartmentIconActive from "../../../assets/images/icons/storeIcon.png"
import DepartmentIcon from "../../../assets/images/icons/storeIcon-deactive.png"
import PullbackIconActive from "../../../assets/images/icons/pullback-colored.png"
import PullbackIcon from "../../../assets/images/icons/pullback.png"
import ScrapIconActive from "../../../assets/images/icons/scrap-colored.png"
import ScrapIcon from "../../../assets/images/icons/scrap.png"
import InnerNavButtons from "./InnerNavButtons"

// import Listing from './WHGIListing'
// import WHCHListing from './WHCHListing'
// import WHTLListing from './WHTLListing'
// import DPGIListing from './DPGIListing'
// import DPTLListing from './DPTLListing'
import DepartmentRequestListing from "./InventoryReq-DepartmentReqListing"
import PullbackRequestListing from "./InventoryReq-PullbackReqListing"
import ScrapReturnRequestListing from "./InventoryReq-ScrapReturnListing"
import ScrapReturnRequestWarehouseListing from "./InventoryReq-SRWarehouseListing"
// import DepartmentIconActive from "../../../assets/images/icons/storeIcon.png"
// import DepartmentIcon from "../../../assets/images/icons/storeIcon-deactive.png"

import { useHistory } from "react-router-dom"

const TopNavButtons = () => {
  const history = useHistory()
  const [activeTab, setActiveTab] = useState("1")

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const ScrapReturnNavItems = [
    {
      title: "Warehouse",
      identifier: "ic1",
      content: <ScrapReturnRequestWarehouseListing />,
    },
    {
      title: "Department",
      identifier: "ic2",
      content: <ScrapReturnRequestListing />,
    },
    // {
    //   title:"Tools",
    //   identifier:'ic3',
    //   content: <WHTLListing />
    // },
  ]
  const DepartmentRequestNavItems = []
  const PullbackRequestNavItems = []
  const TopHeaderNavItems = [
    {
      title: "Departments Requests",
      identifier: "1",
      className: "mx-2",
      activeIcon: DepartmentIconActive,
      icon: DepartmentIcon,
      content: (
        <DepartmentRequestListing
          heading="Inventory"
          iconClass="inventory-req-icon"
          navItems={DepartmentRequestNavItems}
          containerClassName="warehouse-nav-tabs"
          activeTab={activeTab}
        />
      ),
    },
    {
      title: "Pull Back Requests",
      identifier: "2",
      className: "mx-2",
      activeIcon: PullbackIconActive,
      icon: PullbackIcon,
      content: (
        <PullbackRequestListing
          heading="Inventory"
          iconClass="inventory-req-icon"
          navItems={PullbackRequestNavItems}
          containerClassName="warehouse-nav-tabs"
          activeTab={activeTab}
        />
      ),
    },
    {
      title: "Scrap / Return",
      identifier: "3",
      className: "mx-2",
      activeIcon: ScrapIconActive,
      icon: ScrapIcon,
      content: (
        <InnerNavButtons
          heading="Inventory"
          iconClass="inventory-req-icon"
          navItems={ScrapReturnNavItems}
          containerClassName="warehouse-nav-tabs"
          activeTab={activeTab}
        />
      ),
    },
  ]
  return (
    <>
      <div className="casita-container-wrapper">
        <Container fluid>
          <Row>
            <Col className="col-12 ">
              <div className="mb-3">
                <h1>Inventory</h1>
              </div>
              <div className="admin-location mb-4">
                <div className="al-navtabs-main">
                  <Nav tabs className="al-nav-tabs">
                    {TopHeaderNavItems &&
                      TopHeaderNavItems.map(el => {
                        return (
                          <NavItem
                            key={el.identifier}
                            className={`${el.className} al-nav-item d-flex`}
                          >
                            <div
                              className={`mx-4 d-flex justify-content-center align-items-center ${
                                activeTab === el.identifier
                                  ? "bg-navitems-primary"
                                  : "bg-navitems-secondary"
                              }`}
                            >
                              <img
                                src={
                                  activeTab === el.identifier
                                    ? el.activeIcon
                                    : el.icon
                                }
                                className="icon-dim"
                              />
                              <a
                                className="ps-1 d-inline-block al-nav-link "
                                onClick={() => {
                                  toggle(el.identifier)
                                }}
                              >
                                {el.title}
                              </a>
                            </div>
                          </NavItem>
                        )
                      })}
                  </Nav>
                </div>
              </div>
              <div>
                <TabContent activeTab={activeTab}>
                  {TopHeaderNavItems &&
                    TopHeaderNavItems.map(el => {
                      return (
                        <TabPane tabId={el.identifier} key={el.identifier}>
                          <Row>
                            <Col sm="12">
                              {el.identifier == activeTab && el.content}
                            </Col>
                          </Row>
                        </TabPane>
                      )
                    })}
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default TopNavButtons
