import React, { useEffect, useState } from "react"

import "./style.scss"
import { DataTable } from "components/DataTable"
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap"
import { Button } from "components/Button"
import { GetGlobalQuantityById } from "api/api.service"
import { toast } from "react-hot-toast"
import classnames from "classnames"

const COLUMNS = {
  PONUMBER: "P.O. Number",
  PRICE: "Price (Per unit)",
  ORDER_DATE: "Order Date",
  QUANTITY_RECEIVED: "Quantity Received",
  NAME: "Name",
  AVAILABLE_QUANTITY: "Available Quantity",
}

const GlobalQuantityModalDetails = ({
  selectedRowData = {},
  modalCloseHandler,
  footerBtnText = "Done",
}) => {
  const [data, setData] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [activeTabSecond, setActiveTabSecond] = useState("DEPARTMENT")

  const toggle = tab => {
    if (activeTabSecond !== tab) {
      setData([])
      setActiveTabSecond(tab)
    }
  }

  useEffect(() => {
    const getItemsDetails = async () => {
      try {
        setIsFetching(true)
        let res = await GetGlobalQuantityById(selectedRowData?.itemsId, {
          type: activeTabSecond,
        })
        setData(res?.data)
      } catch (err) {
        toast.error(err.message)
      } finally {
        setIsFetching(false)
      }
    }
    getItemsDetails()
  }, [activeTabSecond])

  return (
    <>
      <div className="warehouse-nav-tabs-global px-3">
        <h3 className="text-center inv-req-popup-heading mb-0">
          {selectedRowData?.itemName}
        </h3>
        <h3 className="text-center inv-req-popup-heading">
          ({selectedRowData?.itemSKU})
        </h3>
        <>
          <div className="d-flex justify-content-between ">
            <Nav tabs className="border-0">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTabSecond === "DEPARTMENT",
                  })}
                  onClick={() => {
                    toggle("DEPARTMENT")
                  }}
                >
                  Departments
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTabSecond === "WAREHOUSE",
                  })}
                  onClick={() => {
                    toggle("WAREHOUSE")
                  }}
                >
                  Warehouses
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <TabContent activeTab={activeTabSecond}>
            <TabPane tabId="WAREHOUSE">
              <Row>
                <Col sm="12">
                  <DataTable
                    data={data}
                    className="view-detail-inv-req-table add-req-inv-con table-body-border w-100 finance-vertical-scroll-im"
                    tableClasses="add-req-inv-table"
                    loading={isFetching}
                    config={[
                      {
                        title: COLUMNS.NAME,
                        render: data => {
                          return data?.name
                        },
                      },
                      {
                        title: COLUMNS.AVAILABLE_QUANTITY,
                        render: data => {
                          return data?.quantity
                        },
                      },
                    ]}
                  />
                  <div className="text-center my-4 d-flex justify-content-center align-items-center">
                    <Button
                      size="lg"
                      className="gt-btn-grad-primary table-bottom-btn me-2 d-flex justify-content-center align-items-center"
                      title={<div>{footerBtnText}</div>}
                      onClick={modalCloseHandler}
                    />
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="DEPARTMENT">
              <Row>
                <Col sm="12">
                  <DataTable
                    data={data}
                    className="view-detail-inv-req-table add-req-inv-con table-body-border w-100 finance-vertical-scroll-im"
                    tableClasses="add-req-inv-table"
                    loading={isFetching}
                    config={[
                      {
                        title: COLUMNS.NAME,
                        render: data => {
                          return data?.name
                        },
                      },
                      {
                        title: COLUMNS.AVAILABLE_QUANTITY,
                        render: data => {
                          return data?.quantity
                        },
                      },
                    ]}
                  />
                  <div className="text-center my-4 d-flex justify-content-center align-items-center">
                    <Button
                      size="lg"
                      className="gt-btn-grad-primary table-bottom-btn me-2 d-flex justify-content-center align-items-center"
                      title={<div>{footerBtnText}</div>}
                      onClick={modalCloseHandler}
                    />
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </>
      </div>
    </>
  )
}

export default GlobalQuantityModalDetails
