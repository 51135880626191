import React, { useEffect, useState, useRef } from "react"

import { Button } from "components/Button"
import { DataTable } from "components/DataTable"
import { AutoComplete } from "components/AutoComplete"
import { TextInput } from "components/TextInput"
import {
  Row,
  Col,
  Alert,
  Container,
  Input,
  Label,
  Form
} from "reactstrap"
import { PlaceOrderStatus } from "constants"
import dumyimage from "assets/images/image-upload-placeholder.png"
import { FinanceImageUpload } from "../../../Common/ImageUpload"
import {
  dateTimeFormat,
  convertDateTime,
} from "../../../../utils/dateFormatUtils"
import customStyles from "../HeaderStatusStyling"
import { ImageTypes } from "../../../../constants"
import {
  InventoryItemsById,
  VendorListing,
  CreateOrderFinance,
} from "api/api.service"
import _, { update } from "lodash"
import toast from "react-hot-toast"
import Config from "Config"
import moment from "moment"

const COLUMNS = {
  SELECT_ALL: "Select All",
  ORDER_ID: "Order ID",
  SKU: "SKU No.",
  ITEM_NAME: "Item Name",
  REQUESTED_QUANTITY: "Requested Quantity",
  UNIT_PRICE: "Unit Price",
  TOTAL_PRICE: "Total Price",
  STATUS: "Status",
  ORDERED_QUANTITY: "Ordered Quantity",
  QUANTITY_REMANING: "Quantity Remaining",
  DATE_REQUESTED: "Requested Date",
}

const errorsData = ["", "Field Required", "quantity exceeds"]

const dataKey = "data"
const totalKey = "total"

const KB_CONVERTER = 1024
const MAX_IMG_SIZE = 500

const statusForPayload = status => {
  return status === "order placed"
    ? "OrderPlaced"
    : status === "order substitute"
    ? "OrderedSubstitute"
    : "ParitalOrdered"
}
const addElementToData = dataList => {
  let modifiedArr = _.cloneDeep(dataList)
  modifiedArr.map(el => {
    el.unitPrice = ""
    el.totalPrice = 0
    el.orderedQuantity = ""
    el.isStatusSelect = true
    el.isItemSelect = false
    el.isInputDisabled = true
    el.UPError = 0
    el.qauntityError = 0
    el.itemData = {
      data: [],
      fetching: true,
      page: 1,
      Q: "",
      isDataAvailable: false,
      selectedOption: "",
    }
    if (el.substituteId === null) {
      el.status = "Pending"
      el.isAlreadySubstitute = false
    } else {
      el.status = "order substitute"
      el.isAlreadySubstitute = true
      el.UPError = 1
      el.isInputDisabled = false
      el.qauntityError = 1
    }
  })
  return modifiedArr
}
const PlaceOrderModal = ({
  data,
  title,
  footerBtnType,
  modalCloseHandler,
  dependancyAPIHandler,
}) => {
  let controller = new AbortController()
  let signal = controller.signal
  const [poNo, setPONumber] = useState("")
  const [showError, setShowError] = useState(false)
  const [modifiedData, setModifiedData] = useState(addElementToData(data))
  const [image, setImage] = useState("")
  const [imagePreview, setImagePreview] = useState(dumyimage)
  const [imageUploaded, setImageUploaded] = useState(false)
  const [fileName, setFileName] = useState(undefined)
  const [imageError, setImageError] = useState(undefined)
  const [isAddingRequest, setIsAddingRequest] = useState()
  const [itemSelectData, setItemSelectData] = useState({
    searchValueUpdated: undefined,
    selectedRowIDTypeChange: undefined,
    selectedRowID: undefined,
    selectedRowIDTypeChangeModified: undefined,
    selectedRowIDModified: undefined,
  })
  const [addVendorListing, setAddVendorListing] = useState({
    data: [],
    fetching: true,
    page: 1,
    Q: "",
    isDataAvailable: false,
  })
  const [selectedOptionAV, setSelectedOptionAV] = useState({
    name: "",
    label: "",
    value: "",
  })
  const errorMsgRef = useRef()
  // handle file change
  const handleFileChange = e => {
    let file = e.target.files[0]
    if (file) {
      const uploadedFileType = file?.type?.split("/")[1]?.toLowerCase()
      let fileSize = file && file?.size
      fileSize = fileSize / KB_CONVERTER
      if (!ImageTypes.includes(uploadedFileType)) {
        // setImageUploaded(true)
        setImageError("File type is not supported!")
        // setImageUploaded(false)
        setFileName(undefined)
        setImage("")
        // setImagePreview("")
      } else if (MAX_IMG_SIZE < fileSize) {
        setFileName(undefined)
        setImageError("File size exceeds allowed limit!")
      } else {
        setFileName(file?.name)
        setImageError(undefined)
        let reader = new FileReader()
        reader.onload = e => {
          let imagePreview = e.target?.result
          setImage(file)
          // setImagePreview(imagePreview)
        }
        reader.readAsDataURL(file)
      }
    }
  }
  // handle Input change
  const handleInputChange = (e, id) => {
    let updatedData = _.cloneDeep(modifiedData)
    updatedData.map(el => {
      if (el.id === id) {
        // check for error
        if (e.target.name === "unitPrice") {
          el[e.target.name] = e.target.value
          el.totalPrice = el.unitPrice * el.orderedQuantity
          el.unitPrice > 0 ? (el.UPError = 0) : (el.UPError = 1)
        }
        if (e.target.name === "orderedQuantity") {
          el[e.target.name] = e.target.value
          el.totalPrice = el.unitPrice * el.orderedQuantity
          el.orderedQuantity > 0
            ? (el.qauntityError = 0)
            : (el.qauntityError = 1)
          if (
            el.orderedQuantity >= el.quantity - el.quantityreceived &&
            el.status === "partially ordered"
          ) {
            el.qauntityError = 2
          }
          if (
            el.orderedQuantity > el.quantity - el.quantityreceived &&
            el.status === "order substitute"
          ) {
            el.qauntityError = 2
          }
          if (
            el.orderedQuantity > el.quantity - el.quantityreceived &&
            el.isAlreadySubstitute
          ) {
            el.qauntityError = 2
          }
        }
      }
    })
    setModifiedData(updatedData)
  }
  // update input field number float
  const formatInput = (e, id) => {
    let updatedData = _.cloneDeep(modifiedData)
    updatedData.map(el => {
      if (el.id === id) {
        const num = el.unitPrice
        el[e.target.name] = parseFloat(num).toFixed(2)
      }
    })
    setModifiedData(updatedData)
  }

  const statusOptions =
    PlaceOrderStatus &&
    Object.keys(PlaceOrderStatus)
      .map(el => {
        if (el !== "PENDING") {
          return {
            label: PlaceOrderStatus.getDisplayTextKey(PlaceOrderStatus[el]),
            value: PlaceOrderStatus[el],
          }
        }
      })
      .filter(el => el !== undefined)

  // handle select status change
  const handleSelectStatusChange = (val, id, itemsId) => {
    if (val.value == "order substitute") {
      setItemSelectData({
        ...itemSelectData,
        searchValueUpdated: undefined,
        selectedRowIDTypeChange: id,
        selectedRowIDTypeChangeModified: itemsId,
      })
    }
    let updatedData = _.cloneDeep(modifiedData)
    updatedData.map(el => {
      if (el.id === id) {
        el.status = val.value
        if (el.unitPrice <= 0) el.UPError = 1
        if (val.value == "order placed") {
          el.orderedQuantity = el.quantity - el.quantityreceived
          el.isItemSelect = false
          el.isInputDisabled = true
          el.substituteId = null
          el.qauntityError = 0
        } else if (val.value == "partially ordered") {
          el.isItemSelect = false
          el.orderedQuantity = ""
          el.isInputDisabled = false
          el.substituteId = null
          el.qauntityError = 1
        } else {
          el.isItemSelect = true
          el.orderedQuantity = ""
          el.isInputDisabled = true
          el.qauntityError = 0
          el.itemData.selectedOption = ""
          el.substituteId = null
        }
        el.totalPrice = el.unitPrice * el.orderedQuantity
      }
    })
    setModifiedData(updatedData)
  }
  // handle select item change
  const handleSelectItemChange = (val, id) => {
    let updatedData = _.cloneDeep(modifiedData)
    updatedData.map(el => {
      if (el.id === id) {
        el.substituteId = val ? val.value : null
        el.isInputDisabled = val ? false : true
        el.itemData.selectedOption = val ? val.label : ""
        el.qauntityError = val ? 1 : 0
      }
    })
    setModifiedData(updatedData)
  }

  // API call for select item on value change
  useEffect(() => {
    if (
      itemSelectData.selectedRowID &&
      itemSelectData.searchValueUpdated !== undefined
    )
      getItemsListingData()

    return () => {
      controller.abort()
    }
  }, [itemSelectData.searchValueUpdated])

  // API call for select item on type change
  useEffect(() => {
    if (itemSelectData.selectedRowIDTypeChange) getItemsListingDataTypeChange()

    return () => {
      controller.abort()
    }
  }, [itemSelectData.selectedRowIDTypeChange])
  // API call function
  const getItemsListingData = () => {
    handleStateSet(
      setModifiedData,
      { fetching: true, data: [] },
      itemSelectData.selectedRowID
    )
    InventoryItemsById(
      itemSelectData.selectedRowIDModified,
      {
        limit: Config.LIMIT,
        ...(itemSelectData.searchValueUpdated && {
          Q: itemSelectData.searchValueUpdated,
        }),
        page: 1,
      },
      signal
    )
      .then(res => {
        let updatedData = _.cloneDeep(modifiedData)
        updatedData.map(el => {
          if (el.id === itemSelectData.selectedRowID) {
            ;(el.itemData.fetching = false),
              (el.itemData.isDataAvailable = res?.data.length === Config.LIMIT),
              (el.itemData.page = 1)
            el.itemData.data = (() => {
              let test = res?.data
                .map(item => ({
                  ...item,
                  value: item.id,
                  label: item.itemName,
                }))
                .filter(innerEl => el.skuNo !== innerEl.skuNo)
              return [...test]
            })()
          }
        })
        setModifiedData(updatedData)
      })
      .finally(() => {
        handleStateSet(
          setModifiedData,
          { fetching: false },
          itemSelectData.selectedRowID
        )
      })
  }

  const getItemsListingDataTypeChange = () => {
    handleStateSet(
      setModifiedData,
      { fetching: true, data: [] },
      itemSelectData.selectedRowIDTypeChange
    )
    InventoryItemsById(
      itemSelectData.selectedRowIDTypeChangeModified,
      {
        limit: Config.LIMIT,
        ...(itemSelectData.searchValueUpdated && {
          Q: itemSelectData.searchValueUpdated,
        }),
        page: 1,
      },
      signal
    )
      .then(res => {
        let updatedData = _.cloneDeep(modifiedData)
        updatedData.map(el => {
          if (el.id === itemSelectData.selectedRowIDTypeChange) {
            ;(el.itemData.fetching = false),
              (el.itemData.isDataAvailable = res?.data.length === Config.LIMIT),
              (el.itemData.page = 1)
            el.itemData.data = (() => {
              let test = res?.data
                .map(item => ({
                  ...item,
                  value: item.id,
                  label: item.itemName,
                }))
                .filter(innerEl => el.skuNo !== innerEl.skuNo)
              return [...test]
            })()
          }
        })
        setModifiedData(updatedData)
      })
      .finally(() => {
        handleStateSet(
          setModifiedData,
          { fetching: false },
          itemSelectData.selectedRowIDTypeChange
        )
      })
  }

  // handle select search
  const handleSearch = (value, id, itemsId) => {
    if (!value) return
    let updatedData = _.cloneDeep(modifiedData)
    updatedData.map(el => {
      if (el.id === id) {
        el.itemData.data = []
        el.itemData.page = 1
        el.itemData.Q = value
      }
      setModifiedData(updatedData)
      setItemSelectData({
        ...itemSelectData,
        selectedRowID: id,
        selectedRowIDModified: itemsId,
        searchValueUpdated: value,
      })
    })
  }

  // handle stateset
  const handleStateSet = (callback, keysObj = {}, id) => {
    callback(prev => {
      let updatedData = _.cloneDeep(prev)
      updatedData.map(el => {
        if (el.id === id) {
          el.itemData = { ...el.itemData, ...(keysObj && keysObj) }
        }
      })
      return updatedData
    })
  }
  // handle Item blur
  const handleItemSelectBlur = (callback, id) => {
    setItemSelectData({
      ...itemSelectData,
      searchValueUpdated: "",
    })
  }

  // ADD VENDOR LOGIC //

  // API request for department listing
  useEffect(() => {
    addVendorLogic(addVendorListing.page)

    return () => {
      controller.abort()
    }
  }, [addVendorListing.Q])

  // sku select API call function
  const addVendorLogic = page => {
    handleStateSetAddVendor(setAddVendorListing, { fetching: true, data: [] })
    VendorListing(
      {
        limit: Config.LIMIT,
        ...(addVendorListing.Q && { Q: addVendorListing.Q }),
        page: page,
      },
      signal
    )
      .then(res => {
        setAddVendorListing(prev => ({
          ...prev,
          fetching: false,
          page: page + 1,
          isDataAvailable: res?.data?.length === Config.LIMIT,
          data: (() => {
            let test = res?.data?.map(item => ({
              ...item,
              value: item.id,
              label: item.vendorName,
            }))
            return [...test, ...prev.data]
          })(),
        }))
      })
      .finally(() => {
        handleStateSetAddVendor(setAddVendorListing, { fetching: false })
      })
  }

  // handle stateset
  const handleStateSetAddVendor = (callback = () => {}, keysObj = {}) => {
    callback(prev => {
      return {
        ...prev,
        ...(keysObj && keysObj),
      }
    })
  }

  // handle autocomplete onselect
  const handleSelectOptionAV = e => {
    setSelectedOptionAV({
      name: e ? e.vendorName : "",
      label: e ? e.label : "",
      value: e ? e.id : "",
    })
  }

  // handle select search
  const handleSearchAV = (value, callback) => {
    if (!value) return

    callback(prev => {
      return {
        ...prev,
        data: [],
        page: 1,
        Q: value,
      }
    })
  }

  // scroll to top
  const scrollToTop = () => {
    errorMsgRef.current?.scrollIntoView({ block: "nearest" })
  }
  //HANDLE FORM SUMBIT
  const handleConfirmRequest = async () => {
    setShowError(false)
    let isError = false
    modifiedData.map(el => {
      if (selectedOptionAV.name === "") isError = true
      if (selectedOptionAV.value === "") isError = true
      if (el.status === "Pending") isError = true
      if (el.orderedQuantity <= 0) isError = true
      if (el.unitPrice <= 0) isError = true
      if (el.orderedQuantity > el.quantity - el.quantityreceived) isError = true
      if (el.status === PlaceOrderStatus.COMPLETED) {
        if (el.orderedQuantity === el.quantity - el.quantityreceived)
          isError = true
      }
      if (el.status === "order substitute") {
        if (el.substituteId === null) isError = true
      }
    })
    if (isError) {
      scrollToTop()
      setShowError(true)
      return
    }

    let payloadObj = { orders: [] }
    payloadObj.vendorId = selectedOptionAV?.value
    payloadObj.vendorName = selectedOptionAV?.name
    // payloadObj.invoiceId = 100;
    payloadObj.poNo = poNo
    modifiedData.map(el => {
      let tempObj = {}
      tempObj.status = el?.status && statusForPayload(el?.status)
      tempObj.quantity = el?.orderedQuantity
      tempObj.itemsId = el?.itemsId
      tempObj.unitPrice = Number(el?.unitPrice)
      tempObj.totalPrice = el?.totalPrice
      if (el?.substituteId !== null) tempObj.substituteId = el.substituteId
      tempObj.warehouseRequestId = el?.warehouseRequestId
      tempObj.warehouseRequestItemId = el.id
      payloadObj.orders.push(tempObj)
    })
    try {
      setIsAddingRequest(true)
      let res = await CreateOrderFinance(payloadObj)
      dependancyAPIHandler()
      toast.success("Order has been placed successfully.")
      modalCloseHandler()
    } catch (err) {
      toast.error(err.message)
    } finally {
      setIsAddingRequest(false)
    }
  }

  return (
    <>
      <Form>
        <h3 className="text-left fm-req-popup-heading mb-0 px-5">{title}</h3>
        <div ref={errorMsgRef} className="mt-3 px-5">
          {showError ? (
            <Alert color="danger">Please insert the complete details.</Alert>
          ) : (
            ""
          )}
        </div>
        <div className="pt-3 px-5">
          <Row>
            <Col sm="6" xs="12" md="5" lg="5" className="my-auto">
              <p className="place-order-top-date my-auto">
                Date of Order Placed:{" "}
                <span>{moment().format("MMM D, YYYY")}</span>
              </p>
            </Col>
            <Col sm="6" xs="12" md="5" lg="5">
              <Label
                for="exampleEmail"
                align="right"
                className="px-0 addnewitem-typo table-column-bold"
              >
                PO No.
              </Label>
              <Input
                className="place-order-head-input"
                type="text"
                name="Item Name"
                id="exampleEmail"
                placeholder="Type PO No"
                value={poNo}
                onChange={e => setPONumber(e.target.value)}
              />
            </Col>
            <Col sm="6" xs="12" md="5" lg="5">
              <Label
                for="exampleSelect"
                align="right"
                className="pl-0 pr-0 px-0 sm-2 addnewitem-typo table-column-bold"
              >
                Add Vendor
              </Label>
              <div className="place-order-table-head-search ">
                <AutoComplete
                  placeholder="Select Vendor"
                  customStyles={customStyles.WarehouseIM}
                  classNamePrefix="place-order-table-head-search"
                  options={addVendorListing?.data}
                  handleBlur={() =>
                    setAddVendorListing(prev => {
                      return {
                        ...prev,
                        page: 1,
                        Q: "",
                      }
                    })
                  }
                  onChange={handleSelectOptionAV}
                  onInputChange={val =>
                    handleSearchAV(val, setAddVendorListing)
                  }
                  closeMenuOnSelect={true}
                  isClearable={true}
                  value={addVendorListing?.data?.filter(
                    x => selectedOptionAV.label === x.label
                  )}
                  isLoading={addVendorListing?.fetching}
                />
              </div>
            </Col>
            <Col sm="12" xs="12" md="12" lg="12" className="px-0 mb-4 mt-3">
              <DataTable
                data={modifiedData}
                className=" mt-3 fm-place-order-table place-order-data-table"
                config={[
                  {
                    title: COLUMNS.SKU,
                    className: "table-column-bold",
                    render: data => data?.skuNo,
                  },
                  {
                    title: COLUMNS.ITEM_NAME,
                    render: data => data?.itemName,
                  },
                  {
                    title: COLUMNS.REQUESTED_QUANTITY,
                    className: "table-column-bold",
                    render: data => data?.quantity,
                  },
                  {
                    title: COLUMNS.UNIT_PRICE,
                    render: data => {
                      return (
                        <div style={{ minWidth: "170px" }}>
                          <span className="d-inline-block input-append-dollar-con ps-2 placeorder-unit-price">
                            $
                            <Input
                              style={{ width: "90%", verticalAlign: "initial" }}
                              onChange={e => handleInputChange(e, data.id)}
                              type="number"
                              onBlur={e => formatInput(e, data.id)}
                              placeholder="0"
                              name="unitPrice"
                              className="view-detail-inv-req-p-ip-con create-order-modal-ip d-inline-block border-0 ps-0"
                              value={data.unitPrice}
                              min="0"
                            />
                          </span>
                          {
                            <span className="text-danger d-block">
                              {errorsData[data?.UPError]}
                            </span>
                          }
                        </div>
                      )
                    },
                  },
                  {
                    title: COLUMNS.TOTAL_PRICE,
                    className: "table-column-bold",
                    render: data => `$${data?.totalPrice.toFixed(2)}`,
                  },
                  {
                    title: COLUMNS.DATE_REQUESTED,
                    render: data => {
                      return convertDateTime({
                        date: data.createdAt,
                        customFormat: dateTimeFormat.casitaCutomDateFormat,
                        dateOnly: true,
                      })
                    },
                  },
                  {
                    title: COLUMNS.STATUS,
                    render: data => {
                      return (
                        <div
                          className={`place-order-status ${PlaceOrderStatus.getLabelClass(
                            data?.status
                          )}`}
                        >
                          {PlaceOrderStatus.getDisplayTextKey(data?.status)}
                        </div>
                      )
                    },
                  },
                  {
                    title: "",
                    render: data => {
                      return (
                        <>
                          {data?.isAlreadySubstitute ? (
                            <>
                              <div>{data?.substitutename}</div>
                            </>
                          ) : (
                            <div className="d-flex">
                              <div className="place-order-table-search-con ">
                                <AutoComplete
                                  isSearchable={false}
                                  placeholder="Select Status"
                                  customStyles={customStyles.WarehouseIM}
                                  classNamePrefix="place-order-table-search"
                                  options={statusOptions.map(el => ({
                                    ...el,
                                    ...(el.value ===
                                      PlaceOrderStatus.COMPLETED &&
                                      data.quantity - data.quantityreceived <=
                                        1 && { isDisabled: true }),
                                  }))}
                                  onChange={val =>
                                    handleSelectStatusChange(
                                      val,
                                      data.id,
                                      data.itemsId
                                    )
                                  }
                                />
                              </div>

                              {data?.isItemSelect && (
                                <div className="place-order-table-search-con ms-2">
                                  <AutoComplete
                                    handleBlur={() =>
                                      handleItemSelectBlur(
                                        setModifiedData,
                                        data.id
                                      )
                                    }
                                    onInputChange={val =>
                                      handleSearch(val, data.id, data.itemsId)
                                    }
                                    isClearable={true}
                                    value={data?.itemData?.data.filter(
                                      el =>
                                        data?.itemData.selectedOption ===
                                        el.label
                                    )}
                                    isLoading={data?.itemData?.fetching}
                                    placeholder="Select Item"
                                    customStyles={customStyles.WarehouseIM}
                                    classNamePrefix="place-order-table-search"
                                    options={data?.itemData?.data}
                                    onChange={val =>
                                      handleSelectItemChange(val, data.id)
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      )
                    },
                  },
                  {
                    title: COLUMNS.QUANTITY_REMANING,
                    render: data => data?.quantity - data?.quantityreceived,
                  },
                  {
                    title: COLUMNS.ORDERED_QUANTITY,
                    render: data => {
                      return (
                        <div>
                          <Input
                            onChange={e => handleInputChange(e, data.id)}
                            className="view-detail-inv-req-p-ip-con create-order-modal-ip input-append-dollar-oq d-inline-block border-0 "
                            placeholder="0"
                            name="orderedQuantity"
                            type="number"
                            value={data.orderedQuantity}
                            max={data?.quantity - data?.quantityreceived}
                            min="0"
                            disabled={data?.isInputDisabled}
                          />
                          {
                            <span className="text-danger d-block">
                              {errorsData[data?.qauntityError]}
                            </span>
                          }
                        </div>
                      )
                    },
                  },
                ]}
              />
            </Col>
          </Row>
        </div>
        <div className="text-center my-4 d-flex justify-content-center align-items-center">
          <Button
            isLoading={isAddingRequest}
            size="lg"
            className="gt-btn-grad-primary btn-height me-2 d-flex justify-content-center align-items-center place-order-btn"
            title={<div>{footerBtnType}</div>}
            onClick={handleConfirmRequest}
          />
        </div>
      </Form>
    </>
  )
}

export default PlaceOrderModal
