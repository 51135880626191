import React, { useState } from "react"

import { Button } from "components/Button"
import { DataTable } from "components/DataTable"
import {
  Row,
  Col,
} from "reactstrap"
import { PlacedOrderStatusOM } from "constants"
import { Modal } from "components/Modal"
import { getMediaPath } from "utils/mediaUtils"
import { dateTimeFormat, convertDateTime } from "../../../utils/dateFormatUtils"

const COLUMNS = {
  SKU: "SKU No.",
  ITEM_NAME: "Item Name",
  REQUESTED_QUANTITY: "Requested Quantity",
  UNIT_PRICE: "Unit Price",
  TOTAL_PRICE: "Total Price",
  DATE_REQUESTED: "Requested Dateed",
  STATUS: "Status",
  ORDERED_QUANTITY: "Ordered Quantity",
  DELIVERED_QUANTITY: "Delivered Quantity",
  SUBSTITUTE_NAME: "Substitute Name",
  ACTION: "Action",
}

const PlacedOrderDetailView = ({
  data,
  title,
  footerBtnType,
  modalCloseHandler,
  isPONO,
}) => {
  const [viewInvoiceModal, setViewInvoiceModal] = useState(false)
  const hasInvoice = Boolean(data?.invoice?.path)
  const handleViewInvoiceDetails = e => {
    setViewInvoiceModal(true)
  }
  return (
    <>
      <h3 className="text-left fm-req-popup-heading mb-0 px-5 mb-5">{title}</h3>
      <div className="pt-3 px-5">
        <Row>
          <Col sm="6" xs="12" md="3" lg="3" className="my-auto">
            <p className="place-order-top-date my-auto">
              {isPONO ? "PO No.:" : "Order No.:"}{" "}
              <span>{isPONO ? data?.poNo : data?.orderNo}</span>
            </p>
          </Col>
          <Col sm="6" xs="12" md="5" lg="5" className="my-auto">
            <p className="place-order-top-date my-auto">
              Date of Order Placed:{" "}
              <span>
                {convertDateTime({
                  date: data.createdAt,
                  customFormat: dateTimeFormat.casitaCutomDateFormat,
                  dateOnly: true,
                })}
              </span>
            </p>
          </Col>
          <Col sm="12" xs="12" md="12" lg="12" className="px-0 mb-5 mt-3">
            <DataTable
              data={data && data.orders}
              className=" mt-3 fm-place-order-table place-order-data-table vertical-scroll-om po-detail-table"
              tableClasses="ad-order-mang-place-order-table"
              config={[
                {
                  title: COLUMNS.SKU,
                  className: "table-column-bold",
                  render: data => data?.skuNo,
                },
                {
                  title: COLUMNS.ITEM_NAME,
                  render: data => data?.itemName,
                },
                {
                  title: COLUMNS.DELIVERED_QUANTITY,
                  className: "table-column-bold",
                  render: data => data?.quantity,
                },
                {
                  title: COLUMNS.UNIT_PRICE,
                  className: "table-column-bold",
                  render: data => {
                    return <div>${data?.price.toFixed(2)}</div>
                  },
                },
                {
                  title: COLUMNS.DATE_REQUESTED,
                  render: data => {
                    return (
                      data.createdAt !== "" &&
                      convertDateTime({
                        date: data.createdAt,
                        customFormat: dateTimeFormat.casitaCutomDateFormat,
                        dateOnly: true,
                      })
                    )
                  },
                },
                {
                  title: COLUMNS.STATUS,
                  render: data => {
                    return (
                      <div
                        className={`place-order-status ${PlacedOrderStatusOM.getLabelClass(
                          data?.status
                        )}`}
                      >
                        {PlacedOrderStatusOM.getDisplayTextKey(data?.status)}
                      </div>
                    )
                  },
                },
                {
                  title: COLUMNS.SUBSTITUTE_NAME,
                  render: data => data?.substitute?.itemName || "-",
                },
                {
                  title: COLUMNS.ACTION,
                  render: data => (
                    <>
                      {hasInvoice ? (
                        <div
                          className="pointer c0-detail-view-finance"
                          onClick={e => handleViewInvoiceDetails(data)}
                        >
                          Preview Invoice
                        </div>
                      ) : (
                        <div>No invoice available</div>
                      )}
                    </>
                  ),
                },
              ]}
            />
          </Col>
        </Row>
      </div>
      {footerBtnType !== "" && (
        <div className="text-center my-4 d-flex justify-content-center align-items-center">
          <Button
            size="lg"
            className="gt-btn-grad-primary btn-height me-2 d-flex justify-content-center align-items-center place-order-btn"
            title={<div>{footerBtnType}</div>}
            onClick={modalCloseHandler}
          />
        </div>
      )}
      <Modal
        isOpen={viewInvoiceModal}
        handleModalToggling={setViewInvoiceModal}
        bodyClassName="p-0"
        customButton={true}
        hideModalHeaderSeparator={true}
        headerClasses="pb-0"
        className="inv-req-popup-viewdetails"
        size="lg"
      >
        <>
          <div className="pt-3 px-5 py-3">
            <Row>
              <Col className="my-auto">
                <h3 className="text-left mb-0 px-5 mb-3 invoice-img-heading">
                  Invoice
                </h3>
                {data && data.invoice ? (
                  <img className="image-wh" src={getMediaPath(data?.invoice)} />
                ) : (
                  <div className="text-center ft-15 mb-3">No Data Found!</div>
                )}
              </Col>
            </Row>
          </div>
        </>
      </Modal>
    </>
  )
}

export default PlacedOrderDetailView
