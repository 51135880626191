export const APP_ROUTES = {
  SPLASH: "/get-started",
  LOGIN: "/auth/login",
  FORGET_PASSWORD: "/auth/forgot-password",
  FORGET_VERIFY_CODE: "/auth/forget-verify-code",
  RESET_PASSWORD: "/auth/reset-password",
  DASHBOARD: "/dashboard",
  USER_PROFILE: "/profile",
  SETTINGS: "/settings",
  INVENTORY: "/inventory",
  INVENTORY_REQUEST: "/inventory-request",
  ORDER_MANAGEMENT: "/order-management",
  INVENTORY_MANAGEMENT: "/inventory-management",
  LOCATION: "/locations",
  USER_MANAGEMENT: "/user-management",
  VENDOR_MANAGEMENT: "/vendor-management",
  FAQS: "/faqs",
  ADMIN_LOCATION_WAREHOUSE: "/locations/warehouse",
  ADMIN_LOCATION_DEPARTMENT: "/locations/department",
  ADMIN_ORDER_MANAGEMENT: "/admin-order-management",
  INVENTORY_WAREHOUSE: "/inventory/warehouse",
  INVENTORY_DEPARTMENT: "/inventory/department",
  INVENTORY_WAREHOUSE_GI: "/inventory/warehouse/gi",
  INVENTORY_WAREHOUSE_CHEMICALS: "/inventory/warehouse/chemicals",
  INVENTORY_WAREHOUSE_TOOLS: "/inventory/warehouse/tools",
  INVENTORY_DEPARTMENT_GI: "/inventory/department/gi",
  INVENTORY_DEPARTMENT_CHEMICALS: "/inventory/department/chemicals",
  INVENTORY_DEPARTMENT_TOOLS: "/inventory/department/tools",
  INVENTORY_REQUEST_DEPARTMENT: "/inventory-request/department",
  INVENTORY_REQUEST_PULLBACK: "/inventory-request/pullback",
  INVENTORY_REQUEST_SCRAP: "/inventory-request/scrap",
}

export const getRoute = (route, obj = {}) => {
  if (Object.keys(obj).length) {
    let objectKeys = Object.keys(obj)
    objectKeys.forEach(item => {
      route = route.replace(new RegExp(/:([\d\w?])+/, "i"), match => {
        let formattedMatchedValue =
          match[match.length - 1] === "?"
            ? match.slice(1, match.length - 1)
            : match.slice(1)
        return obj[formattedMatchedValue]
      })
    })
    return route
  }
  return route
}
