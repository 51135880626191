import React, { useState } from "react"
import { CardBody, Card } from "reactstrap"
import { toast } from "react-hot-toast"

import { DataTable } from "components/DataTable"
import PencilIcon from "assets/images/warehouse/inventory-management/pencil-icon.png"
import ActionEyeIcon from "assets/images/inventory-request/action-eye-icon.png"
import DeleteIcon from "assets/images/inventory/delete-icon.png"
import { SearchBox } from "components/SearchBox"
import { Pagination } from "components/Pagination"
import { Modal } from "components/Modal"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import { AddNewItem, GetItemsListing, UpdateItem, CSVFileUpload } from "api/api.service"
import AddNewItemForm from "./AddNewItemForm"
import { Button } from "components/Button"
import MultiAddInventoryModal from './MultiAddInventoryModal';
import UploadInvFileModal from "./UploadInvFileModal"

const DATA_KEY = "data"
const TOTAL_KEY = "total"

const COLUMNS = {
  SKU_NUMBER: "SKU No. ",
  ITEM_NAME: "Item Name",
  ITEM_TYPE: "Item Type",
  ACTION: "Action",
}
const ACTION_TYPES = {
  VIEW_DETAILS: "VIEW_DETAILS",
  EDIT: "EDIT",
}

const ItemsListing = ({}) => {
  // const [modalToggle, setModalToggle] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isFileSubmitLoading, setIsFileSubmitLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState("")
  const [isEdit, setIsEdit] = useState(false)
  const [isReadOnly, setIsReadOnly] = useState(false)
  const [modalStates, setModalStates] = useState({
    modalToggle: false,
    multiAddInventoryModal: false,
    invFileUploadModal: false,
  })

  const {
    data,
    total,
    isFetching,
    page,
    searchText,
    pageSize,
    handleSearchChange,
    handlePageClick,
    onChangePageSize,
    request,
  } = useLocalPaginatedRequest({
    requestFn: GetItemsListing,
    params: {},
    deps: [],
    dataKey: DATA_KEY,
    totalKey: TOTAL_KEY,
  })

  const handleCloseModal = () => {
    setModalStates({...modalStates, modalToggle: false, multiAddInventoryModal: false,})
    setIsReadOnly(false)
    setSelectedItem("")
    setIsEdit(false)
  }

  const handleMultiAddInvModalClose = () => {
    setModalStates({ ...modalStates, multiAddInventoryModal: false })
  }

  // Add Inventory Modal open handler
  const addInventoryModalOpen = () => {
    setModalStates({
      ...modalStates,
      multiAddInventoryModal: false,
      modalToggle: true,
    })
  }

  const handleInvFileUploadModalOpen = () => {
    setModalStates({
      ...modalStates,
      multiAddInventoryModal: false,
      invFileUploadModal: true,
    })
  }

  const handleInvFileUploadModalClose = () => {
    setModalStates({
      ...modalStates,
      invFileUploadModal: false,
    })
  }

  const fileSubmitHandler = async (fileUploaded) => {
    console.log('flu', fileUploaded)
    let payload = new FormData();
    payload.append('file', fileUploaded)
    console.log(payload.get('file'))
    try {
    setIsFileSubmitLoading(true)
    await CSVFileUpload(payload)
    request()
    handleInvFileUploadModalClose()
  } catch (err) {
    toast.error(err.message)
  } finally {
    setIsFileSubmitLoading(false)
  }
}

  const handleSelectItem = (action, data) => {
    if (action == ACTION_TYPES.VIEW_DETAILS) {
      setSelectedItem(data)
      setModalStates({
      ...modalStates,
      modalToggle: true,
    })
      setIsReadOnly(true)
      setIsEdit(false)
    } else if (action == ACTION_TYPES.EDIT) {
      setSelectedItem(data)
      setModalStates({
      ...modalStates,
      modalToggle: true,
    })
      setIsEdit(true)
      setIsReadOnly(false)
    }
  }

  const handleAddItem = async payload => {
    if (isReadOnly) {
      handleCloseModal()
      return
    }
    try {
      setIsLoading(true)
      const response = await AddNewItem(payload)
      toast.success("Item has been added successfully.")
      handleCloseModal()
    } catch (err) {
      toast.error(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleUpdateItem = async (id, payload) => {
    try {
      setIsLoading(true)
      const response = await UpdateItem(id, payload)
      toast.success("Item has been updated successfully.")
      handleCloseModal()
    } catch (err) {
      toast.error(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = async formData => {
    let id = formData.id
    let payload = {
      // price: 0,
      itemName: formData.itemName,
      skuNo: formData.skuNo,
      itemType: formData.itemType,
      itemType: formData.itemType,
      description: formData.description,
      majorThreshold: Math.abs(formData.majorThreshold),
      minorThreshold: Math.abs(formData.minorThreshold),
      mediumThreshold: Math.abs(formData.mediumThreshold),
      itemsCategoryId: formData.itemsCategoryId,
    }

    if (isEdit) {
      await handleUpdateItem(id, payload)
    } else {
      await handleAddItem(payload)
    }

    !isReadOnly && request()
  }
  return (
    <>
      <Card className="mb-5">
        <CardBody className="p-2 p-md-4 wh-im-wh-gi-con">
          <div className="mb-2 mb-md-3">
            <div className="">
              <div className="gi-finance-inner-header finance-item-listing-header">
                <Button
                  size="lg"
                  title="Add Inventory"
                  className="gt-btn-grad-primary add-inv-btn me-0 me-md-2 wh-im-dpgi-status ft-table-header"
                  onClick={() => setModalStates({ ...modalStates, multiAddInventoryModal: true })}
                />
                <div className="fm-location-search-box">
                  <SearchBox
                    inputClasses="vendor-search-input input-search gi-im-search-ip"
                    labelClass="d-none"
                    placeholder="Search"
                    searchIcon={true}
                    searchIconClass="vendor-table-search-icon"
                    conClass="vendor-table-search-inner-class"
                    searchText={searchText}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <DataTable
            data={data}
            loading={isFetching}
            tableClasses="finance-im-wh-chemicals"
            config={[
              {
                title: COLUMNS.SKU_NUMBER,
                render: data => {
                  return <>{data?.skuNo || "-"}</>
                },
              },
              {
                title: COLUMNS.ITEM_NAME,
                render: data => {
                  return data?.itemName || "-"
                },
              },
              {
                title: COLUMNS.ITEM_TYPE,
                render: data => {
                  return data?.itemType || "-"
                },
              },

              {
                title: COLUMNS.ACTION,
                className:"item-listing-action",
                render: data => (
                  <>
                    <span className="text-success pointer">
                      <img
                        src={PencilIcon}
                        onClick={() =>
                          handleSelectItem(ACTION_TYPES.EDIT, data)
                        }
                      />
                      <img
                        src={ActionEyeIcon}
                        className="mx-4"
                        onClick={() =>
                          handleSelectItem(ACTION_TYPES.VIEW_DETAILS, data)
                        }
                      />
                    </span>
                  </>
                ),
              },
            ]}
          />
          <Pagination
            currentPage={page}
            totalData={total}
            onPageClick={handlePageClick}
            rowLimit={pageSize}
          />
        </CardBody>
      </Card>
      <Modal
        isOpen={modalStates.modalToggle}
        handleModalToggling={handleCloseModal}
        bodyClassName="p-0"
        customButton={true}
        hideModalHeaderSeparator={true}
        headerClasses="pb-0"
        className="inv-req-popup-viewdetails"
        sizeClasses="5"
      >
        <AddNewItemForm
          isNative
          isReadOnly={isReadOnly}
          modalCloseHandler={handleCloseModal}
          title={
            isEdit
              ? "Update Item"
              : isReadOnly
              ? "Item Details"
              : "Add New Item"
          }
          footerBtnType="Done"
          handleFormSubmit={handleSubmit}
          isLoading={isLoading}
          data={selectedItem}
        />
      </Modal>
      <Modal
        isOpen={modalStates.multiAddInventoryModal}
        handleModalToggling={handleMultiAddInvModalClose}
        bodyClassName="p-0"
        customButton={true}
        hideModalHeaderSeparator={true}
        headerClasses="pb-0"
        className="inv-req-popup-viewdetails"
        size="sm"
      >
        <MultiAddInventoryModal
          modalCloseHandler={handleMultiAddInvModalClose}
          handleInvFileUploadModalOpen={handleInvFileUploadModalOpen}
          addInventoryModalOpen={addInventoryModalOpen}
          title="Add Items"
        />
      </Modal>
      <Modal
        isOpen={modalStates.invFileUploadModal}
        handleModalToggling={handleInvFileUploadModalClose}
        bodyClassName="p-0"
        customButton={true}
        hideModalHeaderSeparator={true}
        headerClasses="pb-0"
        className="inv-req-popup-viewdetails"
        size="sm"
      >
        <UploadInvFileModal
          modalCloseHandler={handleInvFileUploadModalClose}
          title="ADD ITEMS"
          fileSubmitHandler={fileSubmitHandler}
          isFileSubmitLoading={isFileSubmitLoading}
        />
      </Modal>
    </>
  )
}

export default ItemsListing
