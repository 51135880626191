import { injectBaseConstantMethods } from "./BaseConstants"

const UserRoles = {
  User: "USER",
  Admin: "ADMIN",
  Executive: "EXECUTIVE",
  Production: "PRODUCTION",
  Department: "DEPARTMENT",
  Finance: "FINANCE",
  Warehouse: "WAREHOUSE",
}

const displayTextKeys = {
  [UserRoles.Admin]: "Admin",
  [UserRoles.Department]: "Department Head",
  [UserRoles.Executive]: "Executive",
  [UserRoles.Finance]: "Finance Manager",
  [UserRoles.Production]: "Production Manager",
  [UserRoles.User]: "User",
  [UserRoles.Warehouse]: "Warehouse Manager",
}

const labelClass = {
  [UserRoles.Admin]: "admin-role",
  [UserRoles.Department]: "department-role",
  [UserRoles.Executive]: "executive-role",
  [UserRoles.Finance]: "finance-role",
  [UserRoles.Production]: "production-role",
  [UserRoles.User]: "user-role",
  [UserRoles.Warehouse]: "warehouse-role",
}

export default injectBaseConstantMethods(UserRoles, displayTextKeys, labelClass)
