import React, { useState, useEffect } from "react"
import { CardBody, Card } from "reactstrap"

import { DataTable } from "components/DataTable"
import { AutoComplete } from "components/AutoComplete"
import customStyles from "./AutocompleteStyling"
import ActionEyeIcon from "assets/images/icons/feather-eye-icon.png"
import { Pagination } from "components/Pagination"
import { Modal } from "components/Modal"

import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"

import {
  GetDepartmentInventoryListing,
  GetDepartmentsListing,
  GetItemsListing,
} from "api/api.service"
import Config from "Config"
import GlobalQuantityModal from "../../Common/GlobalInventoryViewModal.js/GlobalQuantityModal"
import { getItemThresholdClass } from "utils/itemThresholdUtil"

// :TODO: Need to be change when integrate casita apis
// ******-------------------------------
const DATA_KEY = "data"
const TOTAL_KEY = "total"
// ******-------------------------------

const COLUMNS = {
  CHECK_BOXES: "",
  SKU_NO: "SKU No.",
  ITEM_NAME: "Item Name",
  DEPARTMENT: "Department",
  COMMITTED: "Committed",
  AVAILABLE: "Available",
  INSTOCK: "Instock",
  GLOBAL_QUANTITY: "Global Quantity",
  ACTION: "Action",
}

const DPGIListing = () => {
  let controller = new AbortController()
  let signal = controller.signal
  const [departmentListing, setDepartmentListing] = useState({
    data: [],
    fetching: true,
    page: 1,
    Q: "",
    isDataAvailable: false,
  })
  const [skuListing, setSKUListing] = useState({
    data: [],
    fetching: true,
    page: 1,
    Q: "",
    isDataAvailable: false,
  })
  const [selectedRowID, setSelectedRowID] = useState({
    itemsId: "",
    itemName: "",
    itemSKU: "",
  })
  const [dataRefetch, setDataRefetch] = useState(false)
  const [filters, setFilters] = useState(null)
  const [itemDes, setItemDes] = useState("")
  const [selectedOption, setSelectedOption] = useState("")
  const [modalStates, setModalStates] = useState({
    pullBackInventoryModal: false,
    viewDetailModal: false,
    globalQuantityModal: false,
  })

  const {
    page,
    handlePageClick,
    pageSize,
    onChangePageSize,
    data,
    total,
    isFetching,
  } = useLocalPaginatedRequest({
    requestFn: GetDepartmentInventoryListing,
    params: {
      ...(filters?.department && { departmentId: filters?.department }),
      ...{ itemType: "General" },
      ...(selectedOption !== "" && { skuNo: selectedOption }),
    },
    deps: [dataRefetch, filters, selectedOption],
    dataKey: DATA_KEY,
    totalKey: TOTAL_KEY,
  })

  const handleCloseModal = () => {
    setItemDes("")
    setModalStates({
      viewDetailModal: false,
      globalQuantityModal: false,
    })
  }

  // Change handler department select
  const handleSelectOptionDepartment = (name, option) => {
    let tempObj = [...option]
    let arrTemp = []
    tempObj = tempObj.map(el => arrTemp.push(el.value))
    setFilters({ ...filters, [name]: arrTemp.toString() })
  }

  // department select API call
  useEffect(() => {
    getDepartmentsListingData(departmentListing.page)

    return () => {
      controller.abort()
    }
  }, [departmentListing.Q])

  // department select API call function
  const getDepartmentsListingData = page => {
    handleStateSet(setDepartmentListing, { fetching: true, data: [] })
    GetDepartmentsListing(
      {
        limit: Config.LIMIT,
        ...(departmentListing.Q && { Q: departmentListing.Q }),
        page: page,
      },
      signal
    )
      .then(res => {
        setDepartmentListing(prev => ({
          ...prev,
          fetching: false,
          page: page + 1,
          isDataAvailable: res?.data?.length === Config.LIMIT,
          data: (() => {
            let test = res?.data?.map(item => ({
              ...item,
              value: item.id,
              label: item.departmentName,
            }))
            return [...test, ...prev.data]
          })(),
        }))
      })
      .finally(() => {
        handleStateSet(setDepartmentListing, { fetching: false })
      })
  }

  // handle select search
  const handleSearch = (value, callback) => {
    if (!value) return

    callback(prev => {
      return {
        ...prev,
        data: [],
        page: 1,
        Q: value,
      }
    })
  }

  // sku select API call function
  const getSKUListing = page => {
    handleStateSet(setSKUListing, { fetching: true, data: [] })
    GetItemsListing(
      {
        limit: Config.LIMIT,
        ...(skuListing.Q && { Q: skuListing.Q }),
        page: page,
      },
      signal
    )
      .then(res => {
        setSKUListing(prev => ({
          ...prev,
          fetching: false,
          page: page + 1,
          isDataAvailable: res?.data?.length === Config.LIMIT,
          data: (() => {
            let test = res?.data
              ?.map(item => ({
                ...item,
                value: item.skuNo,
                label: item.skuNo,
              }))
              ?.filter(item => item.value.toString().length != 0)
            return [...test, ...prev.data]
          })(),
        }))
      })
      .finally(() => {
        handleStateSet(setSKUListing, { fetching: false })
      })
  }

  // handle stateset
  const handleStateSet = (callback = () => {}, keysObj = {}) => {
    callback(prev => {
      return {
        ...prev,
        ...(keysObj && keysObj),
      }
    })
  }
  // API request for department listing
  useEffect(() => {
    getSKUListing(skuListing.page)

    return () => {
      controller.abort()
    }
  }, [skuListing.Q])

  // handle autocomplete onselect
  const handleSelectOption = e => {
    setSelectedOption(e ? e.label : "")
  }

  // item details handler
  const handleOpenDesModal = data => {
    setItemDes(data)
    setModalStates(prev => ({ ...prev, viewDetailModal: true }))
  }

  const GlobalQuantityHandler = (id, name, SKU) => {
    // console.log('id:', id)
    setSelectedRowID({
      ...selectedRowID,
      itemsId: id,
      itemName: name,
      itemSKU: SKU,
    })
    setModalStates({
      ...modalStates,
      globalQuantityModal: true,
    })
  }
  return (
    <>
      <Card className="mb-5">
        <CardBody className="p-2 p-md-4 wh-im-wh-gi-con">
          <div className="mb-2 mb-md-3">
            <div className="gi-finance-inner-header">
              <div className="status-search-con wh-im-dpgi-status pr-table-header">
                <AutoComplete
                  handleBlur={() =>
                    setDepartmentListing(prev => {
                      return {
                        ...prev,
                        page: 1,
                        Q: "",
                      }
                    })
                  }
                  placeholder="All Departments"
                  customStyles={customStyles.WarehouseIM}
                  onInputChange={val => handleSearch(val, setDepartmentListing)}
                  onChange={val =>
                    handleSelectOptionDepartment("department", val)
                  }
                  isLoading={departmentListing?.fetching}
                  isMulti
                  classNamePrefix="status-header-search-admin"
                  options={departmentListing?.data}
                />
              </div>
              <div className="status-search-con wh-im-dpgi-status me-0 department-gi-top-autocomplete">
                <AutoComplete
                  handleBlur={() =>
                    setSKUListing(prev => {
                      return {
                        ...prev,
                        page: 1,
                        Q: "",
                      }
                    })
                  }
                  placeholder="Select SKU Number"
                  customStyles={customStyles.WarehouseIM}
                  onChange={handleSelectOption}
                  onInputChange={val => handleSearch(val, setSKUListing)}
                  closeMenuOnSelect={true}
                  isClearable={true}
                  value={skuListing?.data?.filter(
                    x => selectedOption === x.label
                  )}
                  isLoading={skuListing?.fetching}
                  classNamePrefix="status-header-search-admin"
                  options={skuListing?.data}
                />
              </div>
            </div>
          </div>
          <DataTable
            data={data}
            tableClasses="finance-inventory-management-department-general-inventory-table"
            loading={isFetching}
            config={[
              {
                title: COLUMNS.SKU_NO,
                render: data => {
                  return <>{data?.skuNo}</>
                },
              },
              {
                title: COLUMNS.ITEM_NAME,
                render: data => {
                  return data?.itemName
                },
              },
              {
                title: COLUMNS.DEPARTMENT,
                render: data => {
                  return data?.department?.departmentName
                },
              },
              {
                title: COLUMNS.COMMITTED,
                render: data => {
                  return data?.committed
                },
              },
              {
                title: COLUMNS.AVAILABLE,
                render: data => {
                  return data?.inStock - data?.committed
                },
              },
              {
                title: COLUMNS.INSTOCK,
                render: data => {
                  return data?.inStock
                },
              },
              {
                title: COLUMNS.GLOBAL_QUANTITY,
                render: data => {
                  return (
                    <div
                      className={`wh-im-global-quantity ${getItemThresholdClass(
                        data?.globalTotal,
                        data?.majorThreshold,
                        data?.mediumThreshold,
                        data?.minorThreshold
                      )}`}
                      onClick={e =>
                        GlobalQuantityHandler(
                          data?.itemsId,
                          data?.itemName,
                          data?.skuNo
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {data?.globalTotal}
                    </div>
                  )
                },
              },
              {
                title: COLUMNS.ACTION,
                render: data => (
                  <>
                    <span
                      className="text-success pointer"
                      onClick={() => handleOpenDesModal(data)}
                    >
                      <img src={ActionEyeIcon} />
                    </span>
                  </>
                ),
              },
            ]}
          />
          <Pagination
            currentPage={page}
            totalData={total}
            onPageClick={handlePageClick}
            rowLimit={pageSize}
          />
        </CardBody>
      </Card>
      <Modal
        isOpen={modalStates.viewDetailModal}
        handleModalToggling={handleCloseModal}
        sizeClasses="5"
        bodyClassName="p-0"
        customButton={true}
        hideModalHeaderSeparator={true}
        headerClasses="pb-0"
        className="inv-req-popup-viewdetails"
      >
        <h3 className="text-center inv-req-popup-heading">{`${itemDes?.itemName} (${itemDes.skuNo})`}</h3>
        <div className="p-4">
          <h4 className="inventory-description">Product Summary</h4>
          <p className="mt-1 text-center">{`${itemDes?.description || "-"}`}</p>
        </div>
      </Modal>
      <Modal
        isOpen={modalStates.globalQuantityModal}
        handleModalToggling={handleCloseModal}
        sizeClasses="5"
        bodyClassName="p-0"
        customButton={true}
        hideModalHeaderSeparator={true}
        headerClasses="pb-0"
        className="inv-req-popup-viewdetails"
      >
        <GlobalQuantityModal
          modalCloseHandler={handleCloseModal}
          selectedRowData={selectedRowID}
        />
      </Modal>
    </>
  )
}

export default DPGIListing
