import {
  Login,
  GetMe,
  ForgotPassword,
  ForgotPasswordVerification,
  ResetPassword,
  Logout,
  ResendOtpCode,
} from "api/api.service"
import { SET_DEVICE_ID } from "./deviceIdActions"
import { getSizes } from "./productsActions"
import { getUnreadNotificationCount } from "./notificationsActions"
import { toast } from "react-hot-toast"

export const ME_REQUEST = "ME_REQUEST"
export const ME_SUCCESS = "ME_SUCCESS"
export const ME_ERROR = "ME_ERROR"
export const SET_TOKEN = "SET_TOKEN"
export const SIGN_OUT = "SIGN_OUT"

export const getMe = () => (dispatch, getState) => {
  const token = getState().userAuth.user.Token
  dispatch({ type: ME_REQUEST })
  return GetMe()
    .then(async res => {
      dispatch({ type: ME_SUCCESS, payload: res })
      localStorage.isAuthenticated = true

      return Promise.resolve(res)
    })
    .catch(err => {
      dispatch({ type: ME_ERROR })
      return Promise.reject(err)
    })
}

export const login =
  ({ Email, Password, Type }) =>
  (dispatch, getState) => {
    // const deviceId = getState()?.deviceId
    return Login({
      email: Email,
      password: Password,
      // Type,
      // ...(deviceId && { DeviceUUID: deviceId }),
    })
      .then(async res => {
        if (res.type == "DEPARTMENT" || res.type == "WAREHOUSE") {
          localStorage.clear()
          dispatch({ type: SIGN_OUT })
          toast.error("Not Authorize !")
          return
        }
        dispatch({ type: SET_TOKEN, token: res.token })
        // dispatch({ type: SET_DEVICE_ID, deviceId: res.DeviceUUID })
        await dispatch(getMe())
        return Promise.resolve(res)
      })
      .catch(err => {
        console.log('there', err)
        return Promise.reject(err)
      })
  }

export const forgotPassword =
  ({ Email }) =>
  (dispatch, getState) => {
    return ForgotPassword({ Email })
      .then(async res => {
        return Promise.resolve(res)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }

export const forgotPasswordVerification =
  ({ Code, TokenUUID }) =>
  (dispatch, getState) => {
    return ForgotPasswordVerification({ Code, TokenUUID })
      .then(async res => {
        return Promise.resolve(res)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }

export const resetPassword =
  ({ Code, TokenUUID, Password }) =>
  (dispatch, getState) => {
    return ResetPassword({ Code, TokenUUID, Password })
      .then(async res => {
        return Promise.resolve(res)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }

export const resendOtpCode =
  ({ TokenUUID }) =>
  (dispatch, getState) => {
    return ResendOtpCode({ TokenUUID })
      .then(async res => {
        return Promise.resolve(res)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }

export const logout = () => (dispatch, getState) => {
  return Logout()
    .then(async res => {
      return Promise.resolve(res)
    })
    .catch(err => {
      return Promise.reject(err)
    })
}
