import React, { useState, useEffect } from "react"
import { CardBody, Card } from "reactstrap"
import { toast } from "react-hot-toast"

import { SearchBox } from "components/SearchBox"
import ActionEyeIcon from "assets/images/icons/feather-eye-icon.png"
import { Pagination } from "components/Pagination"
import { DataTable } from "components/DataTable"
import { AutoComplete } from "components/AutoComplete"
import PullbackRequestItems from "./PullbackRequestItems"
import { Modal } from "components/Modal"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import { InventoryReqStatus } from "constants"
import { dateTimeFormat, convertDateTime } from "utils/dateFormatUtils"
import customStyles from "../Inventory/AutocompleteStyling"
import {
  GetPullbackRequestListing,
  GetDepartmentsListing,
  DepartmentFulfillmentByWarehouse,
} from "api/api.service"
import Config from "Config"

const COLUMNS = {
  REQUEST_NO: "Request No.",
  DATE_OF_REQUEST: "Requested Date",
  DEPARTMENT: "Department",
  STATUS: "Status",
  ACTION: "Action",
}
const DATA_KEY = "data"
const TOTAL_KEY = "total"

const DepartmentRequestListing = () => {
  let controller = new AbortController()
  let signal = controller.signal
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedData, setSelectedData] = useState({})
  const [statusFilters, setStatusFilters] = useState(null)
  const [departmentsListing, setDepartmentsListing] = useState({
    data: [],
    fetching: true,
    page: 1,
    isDataAvailable: true,
  })
  const [modalToggle, setModalToggle] = useState(false)
  const [departmentsFilter, setDepartmentsFilter] = useState(null)

  const {
    data,
    page,
    pageSize,
    total,
    isFetching,
    searchText,
    request,
    handleSearchChange,
    handlePageClick,
    onChangePageSize,
  } = useLocalPaginatedRequest({
    requestFn: GetPullbackRequestListing,
    params: {
      ...(statusFilters?.value && { requestStatus: statusFilters?.value }),
      ...(departmentsFilter?.length && {
        departmentId: departmentsFilter.map(item => item.value).join(),
      }),
    },
    deps: [statusFilters, departmentsFilter],
    dataKey: DATA_KEY,
    totalKey: TOTAL_KEY,
  })

  useEffect(() => {
    handleDepartmentsListing(departmentsListing.page)

    return () => {
      controller.abort()
    }
  }, [departmentsListing.Q])

  const handleSelectDepartments = option => {
    if (typeof option != "object" || !option) return
    setDepartmentsFilter(option)
  }
  const handleDepartmentSearch = (value = "", callback) => {
    if (!value) return

    callback(prev => {
      return {
        ...prev,
        data: [],
        page: 1,
        Q: value,
      }
    })
  }

  const handleDepartmentsListing = page => {
    setDepartmentsListing(prev => {
      return {
        ...prev,
        fetching: true,
      }
    })

    GetDepartmentsListing(
      {
        Limit: Config.LIMIT,
        ...(departmentsListing.Q && { Q: departmentsListing.Q }),
        Page: page,
      },
      signal
    )
      .then(res => {
        setDepartmentsListing(prev => ({
          ...prev,
          fetching: false,
          page: page + 1,
          isDataAvailable: res?.data?.length == Config.LIMIT,
          data: (() => {
            let test = res?.data?.map(item => ({
              ...item,
              value: item.id,
              label: item.departmentName,
            }))

            return [...test]
          })(),
        }))
      })
      .finally(() => {
        setDepartmentsListing(prev => {
          return {
            ...prev,
            fetching: false,
          }
        })
      })
  }

  const statusSelectOptions = [
    {
      label: "Open",
      value: "Open",
    },
    {
      label: "In Progress",
      value: "InProgress",
    },
    {
      label: "Completed",
      value: "Completed",
    },
  ]

  const handleModalToggle = data => {
    setSelectedData(data || {})
    setModalToggle(prev => !prev)
  }
  const handleSelectStatusOption = option => {
    setStatusFilters(option)
  }

  const handleOnSubmit = async payload => {
    let data = { ...payload }

    try {
      setIsSubmitting(true)
      let res = await DepartmentFulfillmentByWarehouse(data)
      handleModalToggle()
      toast.success("Request has been fulfilled")
      request()
    } catch (err) {
      toast.err(err.message)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <Card className="mb-5">
        <CardBody className="p-2 p-md-4">
          <div className="mb-2">
            <div className="d-flex align-items-center justify-content-between admin-inventory-request-form-fields ">
              <div className="d-flex justify-content-start align-items-center admin-inventory-request-autocomplete">
                <div className="min-250-w margin-right-handle me-2">
                  <AutoComplete
                    isMulti
                    placeholder="Departments"
                    value={departmentsFilter}
                    customStyles={customStyles.WarehouseIM}
                    classNamePrefix="status-header-search-admin"
                    handleBlur={() =>
                      setDepartmentsListing(prev => {
                        return {
                          ...prev,
                          page: 1,
                          Q: "",
                        }
                      })
                    }
                    onInputChange={val =>
                      handleDepartmentSearch(val, setDepartmentsListing)
                    }
                    options={departmentsListing.data}
                    onChange={val => handleSelectDepartments(val)}
                    closeMenuOnSelect={true}
                    isLoading={departmentsListing?.fetching}
                  />
                </div>
                <div className="min-250-w margin-top-handle">
                  <AutoComplete
                    value={statusFilters}
                    isClearable
                    onChange={val => handleSelectStatusOption(val)}
                    isSearchable={false}
                    placeholder="Select status"
                    customStyles={customStyles.WarehouseIM}
                    classNamePrefix="status-header-search-admin"
                    options={statusSelectOptions}
                  />
                </div>
              </div>
              <div className="location-search-box ad-search-container">
                <SearchBox
                  searchText={searchText}
                  onChange={handleSearchChange}
                  // labelClass="wh-im-search-label"
                  inputClasses="vendor-search-input input-search"
                  labelClass="d-none"
                  placeholder="Search"
                  searchIcon={true}
                  searchIconClass="vendor-table-search-icon"
                  conClass="vendor-table-search-inner-class admin-inventory-request-search-box-responsive"
                />
              </div>
            </div>
          </div>
          <DataTable
            data={data}
            loading={isFetching}
            tableClasses="ad-pullback-inventory-request-table"
            config={[
              {
                title: COLUMNS.REQUEST_NO,
                render: data => data.id,
              },
              {
                title: COLUMNS.DATE_OF_REQUEST,
                render: data =>
                  convertDateTime({
                    date: data.createdAt,
                    customFormat: dateTimeFormat.casitaCutomDateFormat,
                    dateOnly: true,
                  }),
              },
              {
                title: COLUMNS.DEPARTMENT,
                render: data => data?.departmentName,
              },
              {
                title: COLUMNS.STATUS,
                render: data => {
                  return (
                    <div
                      className={`inv-req-popup-status ${InventoryReqStatus.getLabelClass(
                        data.status
                      )}`}
                    >
                      {InventoryReqStatus.getDisplayTextKey(data.status)}
                    </div>
                  )
                },
              },
              {
                className: "text-center",
                title: COLUMNS.ACTION,
                render: data => {
                  return (
                    <span
                      className="text-success pointer"
                      onClick={() => handleModalToggle(data)}
                    >
                      <img src={ActionEyeIcon} />
                    </span>
                  )
                },
              },
            ]}
          />
          <Pagination
            currentPage={page}
            totalData={total}
            onPageClick={handlePageClick}
            rowLimit={pageSize}
          />
        </CardBody>
      </Card>
      <Modal
        styles={{ minHeight: "60vh" }}
        isOpen={modalToggle}
        handleModalToggling={handleModalToggle}
        bodyClassName="p-0"
        customButton={true}
        hideModalHeaderSeparator={true}
        headerClasses="pb-0"
        className="inv-req-popup-viewdetails"
        sizeClasses="5"
        // size="xl"
      >
        <PullbackRequestItems
          modalCloseHandler={handleModalToggle}
          data={selectedData}
        />
      </Modal>
    </>
  )
}

export default DepartmentRequestListing
