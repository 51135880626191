import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"

import InnerNavButtons from "./InnerNavButtons"
import { Header } from "../Common/Header"
import OrderRequestsListing from "./OrderRequestsListing"
import PlacedOrdersListing from "./PlacedOrdersListing"
import ScrapReturnRequestListing from "./InventoryReq-ScrapReturnListing"
import ScrapReturnRequestWarehouseListing from "./InventoryReq-SRWarehouseListing"
import PullbackRequestListing from "./PullBackRequestsListing"

import {
  updatePlaceOrderButton,
  CHECK_BOX_UPDATE_MODAL_ITEMS,
} from "store/actions"

const TopNavButtons = ({}) => {
  const param = useParams()

  useEffect(() => {
    console.log("🚀 ", param)
  }, [param])

  const selectedData = useSelector(
    state => state.checkBoxSelect.selectedItemsListLength
  )
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState("1")
  // handle order request tab toggle
  const [sortBy, setSortBy] = useState(false)
  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  // handle Place Order Button onClick
  const placeOrderClickHandler = () => {
    dispatch(updatePlaceOrderButton({ type: CHECK_BOX_UPDATE_MODAL_ITEMS }))
  }
  const buttonData = [
    {
      buttonTitle: "Place Order",
      clickHandler: placeOrderClickHandler,
      className: "finance-place-order-btn inv-manag-topheader-btn",
      size: "lg",
      status: true,
      disabledVar: selectedData,
    },
  ]
  const ScrapReturnNavItems = [
    {
      title: "Warehouse",
      identifier: "ic1",
      content: <ScrapReturnRequestWarehouseListing />,
    },
    {
      title: "Department",
      identifier: "ic2",
      content: <ScrapReturnRequestListing />,
    },
    // {
    //   title:"Tools",
    //   identifier:'ic3',
    //   content: <WHTLListing />
    // },
  ]
  const DepartmentRequestNavItems = []
  const PullbackRequestNavItems = []
  const TopHeaderNavItems = [
    {
      title: "Orders",
      identifier: "1",
      className: "mx-2",
      // activeIcon: DepartmentIconActive,
      // icon: DepartmentIcon,
      content: (
        <OrderRequestsListing
          sortBy={sortBy}
          handleTogglerChange={setSortBy}
          clickHandler={placeOrderClickHandler}
          heading="Inventory"
          iconClass="inventory-req-icon"
          navItems={DepartmentRequestNavItems}
          containerClassName="warehouse-nav-tabs"
          activeTab={activeTab}
        />
      ),
    },
    {
      title: "Placed Orders",
      identifier: "2",
      className: "mx-2",
      // activeIcon: DepartmentIconActive,
      // icon: DepartmentIcon,
      content: (
        <PlacedOrdersListing
          heading="Inventory"
          iconClass="inventory-req-icon"
          navItems={PullbackRequestNavItems}
          containerClassName="warehouse-nav-tabs"
          activeTab={activeTab}
        />
      ),
    },
    {
      title: "Scrap / Return",
      identifier: "3",
      className: "mx-2",
      // activeIcon: DepartmentIconActive,
      // icon: DepartmentIcon,
      content: (
        <InnerNavButtons
          heading="Inventory"
          iconClass="inventory-req-icon"
          navItems={ScrapReturnNavItems}
          containerClassName="warehouse-nav-tabs"
          activeTab={activeTab}
        />
      ),
    },
    {
      title: "Pull Back Requests",
      identifier: "4",
      className: "mx-2",
      // activeIcon: DepartmentIconActive,
      // icon: DepartmentIcon,
      content: (
        <PullbackRequestListing
          heading="Inventory"
          iconClass="inventory-req-icon"
          navItems={PullbackRequestNavItems}
          containerClassName="warehouse-nav-tabs"
          activeTab={activeTab}
        />
      ),
    },
  ]
  return (
    <>
      <div className="casita-container-wrapper">
        <Container fluid>
          <Row>
            <Col className="col-12 ">
              <Header
                headerTitle="Order Management"
                headerTitleClass="gi-top-heading"
                buttonData={buttonData}
                sortBy={sortBy}
                activeTabValue={activeTab}
                classPrefix="finance"
              />

              <div className="admin-location mb-4">
                <div className="al-navtabs-main">
                  <Nav tabs className="al-nav-tabs">
                    {TopHeaderNavItems &&
                      TopHeaderNavItems.map(el => {
                        return (
                          <NavItem
                            key={el.identifier}
                            className={`${el.className} al-nav-item d-flex`}
                          >
                            <div
                              className={`mx-1 d-flex justify-content-center align-items-center ${
                                activeTab === el.identifier
                                  ? "bg-navitems-primary"
                                  : "bg-navitems-secondary"
                              }`}
                            >
                              {/* <img 
                               src={                                
                                activeTab === el.identifier ? el.activeIcon : el.icon
                              }
                              /> */}
                              <a
                                className="ps-1 d-inline-block al-nav-link "
                                onClick={() => {
                                  toggle(el.identifier)
                                }}
                              >
                                {el.title}
                              </a>
                            </div>
                          </NavItem>
                        )
                      })}
                  </Nav>
                </div>
              </div>
              <div>
                <TabContent activeTab={activeTab}>
                  {TopHeaderNavItems &&
                    TopHeaderNavItems.map(el => {
                      return (
                        <TabPane tabId={el.identifier} key={el.identifier}>
                          <Row>
                            <Col sm="12">
                              {el.identifier == activeTab && el.content}
                            </Col>
                          </Row>
                        </TabPane>
                      )
                    })}
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default TopNavButtons
