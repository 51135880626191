import React, { useState } from "react"
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap"
import classnames from "classnames"

const InnerNavButtons = props => {
  const { navItems, containerClassName = "" } = props
  const [activeTabSecond, setActiveTabSecond] = useState("ic1")

  const toggle = tab => {
    if (activeTabSecond !== tab) {
      setActiveTabSecond(tab)
    }
  }

  return (
    <>
      <div className={containerClassName}>
        <>
          <div className="d-flex justify-content-between ">
            <Nav tabs className="border-0">
              {navItems &&
                navItems.map(el => {
                  return (
                    <NavItem key={el.identifier}>
                      <NavLink
                        className={`${classnames({
                          active: activeTabSecond === el.identifier,
                        })} nav-link-finance-${el.identifier}`}
                        onClick={() => {
                          toggle(el.identifier)
                        }}
                      >
                        {el.title}
                      </NavLink>
                    </NavItem>
                  )
                })}
            </Nav>
          </div>
          <TabContent activeTab={activeTabSecond}>
            {navItems &&
              navItems.map(el => {
                return (
                  <TabPane tabId={el.identifier} key={el.identifier}>
                    <Row>
                      <Col sm="12">
                        {activeTabSecond == el.identifier && el.content}
                      </Col>
                    </Row>
                  </TabPane>
                )
              })}
          </TabContent>
        </>
      </div>
    </>
  )
}

export default InnerNavButtons
