import React from "react"
import { Input } from "reactstrap"
import { DebounceInput } from "react-debounce-input"

const SearchBox = ({
  text = "Search",
  searchText = "",
  onChange = () => {},
  placeholder = "",
  searchIcon = false,
  inputClasses = "",
  labelClass = "",
  searchIconClass="",
  conClass="",
}) => {
  return (
    <div
    // className="me-3"
    // className="search-box chat-search-box py-4 gt-search-box me-3"
    >
      <div
        // className="position-relative"
        className={`${searchIcon && "position-relative"} ${conClass}`}
        style={{ whiteSpace: "nowrap" }}
      >
        <label
          htmlFor="search-user"
          className={`me-1 mb-0 table-main-search-label ${labelClass}`}
        >
          Search:
        </label>
        <DebounceInput
          id="search-user"
          type="text"
          className={inputClasses}
          // className="form-control gt-search-box-input"
          style={{ display: "inline-block" }}
          placeholder={placeholder}
          value={searchText}
          onChange={onChange}
          debounceTimeout={600}
        />

        {searchIcon && <i className={`bx bx-search-alt-2 search-icon ${searchIconClass}`} />}
      </div>
    </div>
  )
}

export default SearchBox
