// Non auth public routes
import {
  Auth,
  Login,
  ForgetPassword,
  ForgetVerifyCode,
  ResetPassword,
} from "../pages/Authentication"

// Utils
import { APP_ROUTES } from "../helpers/routeHelpers"
import { withTitle } from "../hoc"
import { PageTitles } from "../constants"

// Admin
import { Faqs as AdminFaqs } from "pages/AdminApp/Faqs"
import { TopNavButtons as AdminInventory } from "pages/AdminApp/Inventory"
import { TopNavButtons as AdminInventoryRequest } from "pages/AdminApp/InventoryRequest"
import { LocationsListing as AdminLocations } from "pages/AdminApp/Locations"
import { OrderManagement as AdminOrderManagement } from "pages/AdminApp/OrderManagement"
import { UserManagementListing as AdminUserManagement } from "pages/AdminApp/UserManagement"

// Finance
import { Faqs as FinanceFaqs } from "pages/FinanceApp/Faqs"
import { InventoryManagement as FinanceInventoryManagement } from "pages/FinanceApp/InventoryManagement"
import { TopNavButtons as FinanceOrderManagement } from "pages/FinanceApp/OrderManagement"

// Common
import { Splash } from "pages/Splash"
import { Settings } from "pages/Settings"
import { Dashboard } from "pages/Dasboard"
import { VendorManagementListing } from "pages/VendorManagement"

const adminRoutes = [
  {
    path: APP_ROUTES.DASHBOARD,
    component: withTitle({
      component: Dashboard,
      title: PageTitles.DASHBOARD,
    }),
    exact: true,
  },
  {
    path: APP_ROUTES.FAQS,
    component: withTitle({
      component: AdminFaqs,
      title: PageTitles.FAQS,
      path: APP_ROUTES.FAQS,
    }),
    exact: true,
  },
  {
    path: APP_ROUTES.INVENTORY_MANAGEMENT,
    component: withTitle({
      component: AdminInventory,
      title: PageTitles.INVENTORY_MANAGEMENT,
    }),
    exact: true,
  },
  {
    path: APP_ROUTES.INVENTORY_REQUEST,
    component: withTitle({
      component: AdminInventoryRequest,
      title: PageTitles.INVENTORY_MANAGEMENT,
    }),
    exact: false,
  },
  {
    path: APP_ROUTES.LOCATION,
    component: withTitle({
      component: AdminLocations,
      title: PageTitles.LOCATION,
    }),
    exact: false,
  },
  {
    path: APP_ROUTES.ORDER_MANAGEMENT,
    component: withTitle({
      component: AdminOrderManagement,
      title: PageTitles.ORDER_MANAGEMENT,
    }),
    exact: true,
  },
  {
    path: APP_ROUTES.ADMIN_ORDER_MANAGEMENT,
    component: withTitle({
      component: AdminOrderManagement,
      title: PageTitles.ORDER_MANAGEMENT,
    }),
    exact: true,
  },
  {
    path: APP_ROUTES.SETTINGS,
    component: withTitle({
      component: Settings,
      title: PageTitles.SETTINGS,
    }),
    exact: true,
  },
  {
    path: APP_ROUTES.USER_MANAGEMENT,
    component: withTitle({
      component: AdminUserManagement,
      title: PageTitles.USER_MANAGEMENT,
    }),
    exact: true,
  },
  {
    path: APP_ROUTES.VENDOR_MANAGEMENT,
    component: withTitle({
      component: VendorManagementListing,
      title: PageTitles.VENDOR_MANAGEMENT,
    }),
    exact: true,
  },
]

const financeRoutes = [
  {
    path: APP_ROUTES.DASHBOARD,
    component: withTitle({
      component: Dashboard,
      title: PageTitles.DASHBOARD,
    }),
    exact: true,
  },
  {
    path: APP_ROUTES.FAQS,
    component: withTitle({
      component: FinanceFaqs,
      title: PageTitles.FAQS,
      path: APP_ROUTES.FAQS,
    }),
    exact: true,
  },
  {
    path: APP_ROUTES.INVENTORY_MANAGEMENT,
    component: withTitle({
      component: FinanceInventoryManagement,
      title: PageTitles.INVENTORY_MANAGEMENT,
    }),
    exact: true,
  },

  {
    path: APP_ROUTES.ORDER_MANAGEMENT,
    component: withTitle({
      component: FinanceOrderManagement,
      title: PageTitles.ORDER_MANAGEMENT,
    }),
    exact: true,
  },
  {
    path: APP_ROUTES.SETTINGS,
    component: withTitle({
      component: Settings,
      title: PageTitles.SETTINGS,
    }),
    exact: true,
  },
  {
    path: APP_ROUTES.VENDOR_MANAGEMENT,
    component: withTitle({
      component: VendorManagementListing,
      title: PageTitles.VENDOR_MANAGEMENT,
    }),
    exact: true,
  },
]

const publicRoutes = [
  // {
  //   path: APP_ROUTES.SPLASH,
  //   component: withTitle({
  //     component: Splash,
  //     title: PageTitles.SPLASH,
  //   }),
  //   exact: true,
  // },

  {
    path: APP_ROUTES.LOGIN,
    component: withTitle({
      component: Auth,
      title: PageTitles.LOGIN,
    }),
  },
  {
    path: APP_ROUTES.FORGET_PASSWORD,
    component: withTitle({
      component: Auth,
      title: PageTitles.FORGET_PASSWORD,
    }),
  },
  {
    path: APP_ROUTES.FORGET_VERIFY_CODE,
    component: withTitle({
      component: Auth,
      title: PageTitles.FORGET_VERIFY_CODE,
    }),
  },
  {
    path: APP_ROUTES.RESET_PASSWORD,
    component: withTitle({
      component: Auth,
      title: PageTitles.RESET_PASSWORD,
    }),
  },
]

export { publicRoutes, adminRoutes, financeRoutes }
