import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import { toast } from "react-hot-toast"

import { Header } from "../Common/Header"
import { DataTable } from "components/DataTable"
import EditIcon from "../../../assets/images/icons/finance-vendor-mang-edit-icon.svg"
import { Pagination } from "components/Pagination"
import { Modal } from "components/Modal"
import AddEditUser from "./modals/AddEditUser"
import DeleteUser from "./modals/DeleteUser"
import { EditUser, UserListing, CreateUser } from "api/api.service"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"

const COLUMNS = {
  ID_NO: "ID",
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  EMAIL_ADDRESS: "Email Address",
  DEPARTMENT: "Department",
  ROLE: "Role",
  STATUS: "Status",
  ACTIONS: "Action",
}

const MODAL_ACTIONS = {
  ADD: "1",
  EDIT: "2",
  DELETE: "3",
}

const DATA_KEY = "data"
const TOTAL_KEY = "total"

const UserManagementListing = ({}) => {
  const [loading, setLoading] = useState(false)
  const [editState, setEditstate] = useState("")
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [dataRefetch, setDataRefetch] = useState(false)
  const [filters] = useState(null)

  const {
    data,
    isFetching,
    page,
    searchText,
    total,
    handleSearchChange,
    request,
    handlePageClick,
  } = useLocalPaginatedRequest({
    requestFn: UserListing,
    params: {
      ...(filters?.Status && { Status: filters?.Status }),
    },
    deps: [dataRefetch, filters],
    dataKey: DATA_KEY,
    totalKey: TOTAL_KEY,
  })
  const [userData, setUserData] = useState([]);
  const [currentActionKey, setCurrentActionKey] = useState(null)
  const [actionStates, setActionStates] = useState({
    isAddUser: null,
    isEditUser: null,
    isDeleteUser: null,
  })

  const handleAddUser = () => {
    setCurrentActionKey(MODAL_ACTIONS.ADD)
    setActionStates(prev => ({ ...prev, isAddUser: true }))
  }

  const handleEditUser = data => {
    setCurrentActionKey(MODAL_ACTIONS.EDIT)
    setActionStates(prev => ({ ...prev, isEditUser: true }))
  }

  const handleCloseModal = () => {
    setActionStates({
      isAddUser: null,
      isEditUser: null,
      isDeleteUser: null,
    })
    setCurrentActionKey(null)
  }

  let currentAction = {
    [MODAL_ACTIONS.ADD]: {
      title: "Add New Employee",
      btnText: "Add Employee",
    },
    [MODAL_ACTIONS.EDIT]: {
      title: "Update Employee",
      btnText: "Update Employee",
    },
    [MODAL_ACTIONS.DELETE]: {
      title: "Delete Employee",
      btnText: "Delete Employee",
    },
  }

  let handleSubmit = async data => {
    let payload = {
      email: data?.emailAddress,
      firstName: data?.firstName,
      lastName: data?.lastName,
      type: data?.role,
      notificationSetting: false,
      phone: "00000000000",
    }

    if (actionStates.isAddUser) {
      await handleAdd(payload)
    } else if (actionStates.isEditUser) {
      await handleEdit(payload)
    }
    request()
  }

  let handleAdd = async payload => {
    try {
      setLoading(true)

      let res = await CreateUser(payload)
      toast.success("User has been added !")
      handleCloseModal()
    } catch (err) {
      toast.error(err.message)
    } finally {
      setLoading(false)
    }
  }

  let handleEdit = async payload => {
    try {
      setLoading(true)
      let res = await EditUser(payload, editState?.id)
      toast.success("User has been Edited !")
      handleCloseModal()
    } catch (err) {
      toast.error(err.message)
    } finally {
      setLoading(false)
    }
  }

  const editUserHandler = data => {
    setEditstate(data)
    handleEditUser()
  }

  const handleStatusChange =  async (userStatus, userId) => {
    let status = userStatus === "ACTIVE" ? 'INACTIVE' : 'ACTIVE'
    let userDataUpdated = [...data]
    userDataUpdated.map(el => {
      if(el?.id == userId) el.status = status;
    })
    setUserData(userDataUpdated)
    let payload = {
      status: status
    }
    try {
      setIsStatusLoading(true)
      let res = await EditUser(payload, userId)
      toast.success("User has been Edited !")
    }catch(err){
      toast.error(err.message)
      let userDataUpdated = [...data]
      userDataUpdated.map(el => {
        if(el?.id == userId) el.status = userStatus
      })
      setUserData(userDataUpdated)
      renderUpdate = true;
    }finally{
      setIsStatusLoading(false)
    }
  }

  useEffect(() => {
    setUserData(data)
  }, [data])

  return (
    <>
      <Row>
        <Col md={12} className="mt-1">
          <Header
            headerTitle="User Management"
            btnTitle="Add New Employee"
            onClick={handleAddUser}
            onChangeHandler={handleSearchChange}
            searchText={searchText}
            headerBtnResponsive="ad-header-btn-responsive"
            searchResponsiveness="admin-user-mangement-search-responsiveness"
            flexResponsive="ad-user-mangement"
            flexResponsiveInner="ad-user-management-inner"
            SearchBoxContainer="ad-search-box-container"
          />
        </Col>
        <Col md={12}>
          <DataTable
            data={userData}
            className="user-mang-data-container mt-3"
            tableClasses="ad-user-mangement-table"
            loading={isFetching}
            config={[
              {
                title: COLUMNS.ID_NO,
                className: "black-color fm-ftwg-600  ",
                render: data => data?.id || "-",
              },
              {
                title: COLUMNS.FIRST_NAME,
                className: "",
                render: data => data?.firstName || "-",
              },
              {
                title: COLUMNS.LAST_NAME,
                className: "",
                render: data => data?.lastName || "-",
              },
              {
                title: COLUMNS.EMAIL_ADDRESS,
                className: "",
                render: data => data?.email || "-",
              },
              {
                title: COLUMNS.ROLE,
                className: "",
                render: data => data?.type || "-",
              },
              {
                title: COLUMNS.STATUS,
                className: "",
                thClass: "status-header",
                render: data => (
                  <>
                  <div className="admin-usermanag-status-toggle">
                  <span>Active</span>
                    <label className="admin-usermanag-status-switch">
                      <input 
                      id={data?.id}
                      disabled={isStatusLoading}
                      type="checkbox" 
                      name={`data-${data?.id}`}
                      onClick={(e) => handleStatusChange(data?.status, data?.id)} 
                      checked={data?.status == 'ACTIVE' ? false : true}
                      />
                      <span className="admin-usermanag-status-slider round"></span>
                    </label>
                    <span>In-active</span>
                  </div>
                  </>
                ),
              },
              {
                title: COLUMNS.ACTIONS,
                className: "",
                render: data => (
                  <span
                    className=" pointer "
                    onClick={() => editUserHandler(data)}
                  >
                    <img src={EditIcon} />
                  </span>
                ),
              },
            ]}
          />
        </Col>
        <Pagination
          currentPage={page}
          totalData={total}
          onPageClick={handlePageClick}
          className="user-mang-pagination-contain"
        />
      </Row>

      <Modal
        isOpen={!!currentAction[currentActionKey]}
        handleModalToggling={handleCloseModal}
        bodyClassName="p-0"
        customButton={true}
        hideModalHeaderSeparator={true}
        headerClasses="modal-header-container"
        size="md"
        backdrop="static"
        modalTitle={currentAction[currentActionKey]?.title}
      >
        {(actionStates.isAddUser || actionStates.isEditUser) && (
          <AddEditUser
            emailAddress={editState.email}
            firstName={editState?.firstName}
            lastName={editState.lastName}
            role={editState.type}
            isAddUser={actionStates.isAddUser}
            isEditUser={actionStates.isEditUser}
            btnText={currentAction[currentActionKey]?.btnText}
            onSubmitForm={handleSubmit}
            loading={loading}
          />
        )}
        {actionStates.isDeleteUser && <DeleteUser />}
      </Modal>
    </>
  )
}

export default UserManagementListing
