import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { connect } from "react-redux"
import { Link } from "react-router-dom"

import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import logo from "../../assets/images/faviconcopy.ico"
import casitaLogoLg from "../../assets/images/casita-logo.svg"
//i18n
import { withTranslation } from "react-i18next"
// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"

const SM_WIDTH = 768
const MD_WIDTH = 1440

const Header = props => {
  const [search, setsearch] = useState(false)
  const [megaMenu, setmegaMenu] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false)

  // //****** Manage Screen Width
  // const [screenSize, getDimension] = useState({
  //   dynamicWidth: window.innerWidth,
  //   dynamicHeight: window.innerHeight,
  // })
  // const setDimension = () => {
  //   getDimension({
  //     dynamicWidth: window.innerWidth,
  //     dynamicHeight: window.innerHeight,
  //   })
  // }
  // useEffect(() => {
  //   window.addEventListener("resize", setDimension)
  //   if (screenSize.dynamicWidth <= SM_WIDTH) {
  //     document.body.classList.toggle("vertical-collpsed")
  //   }
  //   if (
  //     screenSize.dynamicWidth <= MD_WIDTH &&
  //     screenSize.dynamicWidth > SM_WIDTH
  //   ) {
  //     document.body.classList.toggle("vertical-collpsed")
  //     document.body.classList.toggle("sidebar-enable")
  //   }
  //   return () => {
  //     window.removeEventListener("resize", setDimension)
  //   }
  // }, [screenSize])
  // //*****

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    // if (window.screen.width <= 998) {
    if (window.screen.width <= 600) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar" className="gt-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box  d-md-block ">
              <Link to="/" className="logo logo-light">
                {/* Logo will be change with casita */}
                <span className="logo-sm">
                  <img src={logo} alt="" className="casita-favicon" />
                </span>
                <span className="logo-lg">
                  <img src={casitaLogoLg} className="casita-favicon" />
                </span>
              </Link>
            </div>
            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 header-item "
              id="vertical-menu-btn"
            >
              <i className="bx bx-bar-chart bx-rotate-90 bx-sm"></i>
            </button>
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search)
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <NotificationDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
